// ============================================================================
//
//                              Libraries to import
//
// ============================================================================

/*
const librariesToImport = [
  'https://unpkg.com/rxjs@7.8.1/dist/bundles/rxjs.umd.min.js',
];

for (const library of librariesToImport) {
  let script = document.createElement('script');
  script.src = library;
  // Add an attribute data-loaded = 'true' when the script loads
  script.setAttribute('data-loaded', 'false');
  script.onload = () => {
    script.setAttribute('data-loaded', 'true');
    console.info(library + ' loaded');
  }
  document.head.appendChild(script);
}*/


// ============================================================================
//
//                              WEEK CALENDAR
//
// ============================================================================

/*
 * subscribing to this subject will be notified every time changeWeekDay(event) is called
 * example of use:
 *  changeWeekDaySubject.subscribe(()=>{console.log('changed')})
 */

let changeWeekDaySubject = null; // initialized inside changeWeekDay()

/**
 * Generates a week calendar based on the provided date object. Then such day is
 * clicked by the script.
 *
 * @param {Date} dateObject - The middle day of the week.
 * @return {void} This function does not return a value.
 */
function setWeekCalendar(dateObject) {
  // Make and array of 7 days to pass on to updateWekkCalendar()
  // dateObject is the middle day of the array
  let arrayOfSevenJsDates = [];

  // Push three date objects prior to dateObject
  for (let i = -3; i <= -1; i++) {
    let priorDate = new Date(dateObject);
    priorDate.setDate(dateObject.getDate() + i);
    arrayOfSevenJsDates.push(priorDate);
  }

  // Push dateObject
  arrayOfSevenJsDates.push(dateObject);

  // Push three date objects after dateObject
  for (let i = 1; i <= 3; i++) {
    let nextDate = new Date(dateObject);
    nextDate.setDate(dateObject.getDate() + i);
    arrayOfSevenJsDates.push(nextDate);
  }

  // Make an array of properly formatted JS objects with strings in Italian
  const arrayOfSevenJsDatesFormatted = [];
  for (let i = 0; i < arrayOfSevenJsDates.length; i++) {
    const dayStrings = {
      dayName: arrayOfSevenJsDates[i].toLocaleDateString('it-IT', { weekday: 'short' }),
      dayNumber: arrayOfSevenJsDates[i].getDate(),
      dayMonth: arrayOfSevenJsDates[i].getMonth() + 1,
      dayYear: arrayOfSevenJsDates[i].getFullYear()
    };
    arrayOfSevenJsDatesFormatted.push(dayStrings);
  }

  updateWeekCalendar(arrayOfSevenJsDatesFormatted);

  // Set the middle day of the week as active
  const weekCalendar = document.querySelector('.week-calendar');
  const middleDay = weekCalendar.querySelector('.week-day.four');
  middleDay.click();

}

/**
 * Updates the '.week-calendar' with data from the daysArrayofObjects.
 *
 * @param {Array} daysArrayofObjects - An array of objects with dayName, dayNumber, dayMonth, dayYear as keys.
 * @return {undefined} This function does not return a value.
 */
function updateWeekCalendar(daysArrayofObjects) {
  let weekCalendar = document.querySelector('.week-calendar');
  let dayNameList = weekCalendar.querySelectorAll('.week-calendar .day-name');
  let dayNumberList = weekCalendar.querySelectorAll('.week-calendar .day-number');
  let dayMonthList = weekCalendar.querySelectorAll('.week-calendar .day-month');
  let dayYearList = weekCalendar.querySelectorAll('.week-calendar .day-year');

  // Fill days name and number with data from daysJsObject
  for (let i = 0; i < dayNameList.length; i++) {
    try {
      dayNameList[i].innerText = daysArrayofObjects[i].dayName;
      dayNumberList[i].innerText = daysArrayofObjects[i].dayNumber;
      dayMonthList[i].innerText = daysArrayofObjects[i].dayMonth;
      dayYearList[i].innerText = daysArrayofObjects[i].dayYear;
    } catch (error) {
      dayNameList[i].innerText = "Empty";
      dayNumberList[i].innerText = "Data";
      dayMonthList[i].innerText = "Empty";
      dayYearList[i].innerText = "Empty";
    }
  }
}


/**
 * Moves the week calendar based on the specified operation.
 *
 * @param {string} operation - The operation to perform. It can be either 'forward' or 'backward'.
 * @return {undefined} - This function does not return a value.
 */
function moveWeekCalendar(operation) {
  const firstDay = document.querySelector('.week-day.one');
  const dayName = firstDay.querySelector('.day-name').innerText;
  const dayNumber = firstDay.querySelector('.day-number').innerText;
  const dayMonth = firstDay.querySelector('.day-month').innerText;
  const dayYear = firstDay.querySelector('.day-year').innerText;
  const startingDateObject = new Date(`${dayYear}-${dayMonth}-${dayNumber}`);

  // Generate an array of Date Objects with +7 days of displacement
  let arrayOfSevenJsDates = [];
  for (let i = 0; i < 7; i++) {
    let daysToAdd;
    if (operation == 'forward') { daysToAdd = 7 + i; }
    if (operation == 'backward') { daysToAdd = -7 + i; }
    arrayOfSevenJsDates[i] = new Date(startingDateObject);
    arrayOfSevenJsDates[i].setDate(startingDateObject.getDate() + daysToAdd);
  }

  // Generate an array of properly formatted JS objects with strings in Italian
  let arrayOfDateStrings = [];
  for (let i = 0; i < 7; i++) {
    const dayStrings = {
      dayName: arrayOfSevenJsDates[i].toLocaleDateString('it-IT', { weekday: 'short' }),
      dayNumber: arrayOfSevenJsDates[i].getDate(),
      dayMonth: arrayOfSevenJsDates[i].getMonth() + 1, // Months start at 0 in js dates
      dayYear: arrayOfSevenJsDates[i].getFullYear()
    }

    arrayOfDateStrings[i] = dayStrings;
  }

  // Modify the DOM of the week-calendar with the new data
  updateWeekCalendar(arrayOfDateStrings);

  // Click the middle day
  const middleDay = document.querySelector('.week-calendar .week-day.four');
  middleDay.click();
}

/**
 * Changes the selected week day and updates the title and active class accordingly.
 *
 * @param {Event} event - The event object triggered by the user action.
 * @return {void} This function does not return a value.
 */
function changeWeekDay(event) {
  // Get the selected day
  const selectedDay = event.target.closest('.week-day');

  // Get the title element
  const title = document.querySelector('.week-calendar .title');

  // Get the current date selected
  const currentDay = selectedDay.querySelector('.day-number').innerText;
  const currentMonth = selectedDay.querySelector('.day-month').innerText;
  const currentYear = selectedDay.querySelector('.day-year').innerText;

  // Create JS Date object for the selected day
  const currentJSDate = new Date(`${currentYear}-${currentMonth}-${currentDay}`);

  // Generate italian locale long day and month
  const dayName = currentJSDate.toLocaleDateString('it-IT', { weekday: 'long' });
  const monthName = currentJSDate.toLocaleDateString('it-IT', { month: 'long' });

  // Change the title content to the selected day
  title.textContent = `${dayName}, ${currentDay} ${monthName}`;

  // Remove the "active" class from all days
  const days = document.querySelectorAll('.week-day');
  days.forEach(day => {
    day.classList.remove('active');
  });

  // Add the "active" class to the selected day
  selectedDay.classList.add('active');

  // Notify the subscriber
  if (changeWeekDaySubject == null) {
    changeWeekDaySubject = new rxjs.Subject();
  }

  changeWeekDaySubject.next();
}


/**
 * Retrieves the first day with .active class from the .week-calendar.
 *
 * Assumes that there is only one .week-calendar on page.
 *
 * @return {Object} dayToReturn - The first active day in the week calendar.
 * @property {number} dayIndex - The index of the active day.
 * @property {string} dayNumber - The number of the active day.
 * @property {string} monthNumber - The month of the active day.
 * @property {string} yearNumber - The year of the active day.
 * @property {Date} dateObj - The date of the active day.
 */
function getWeekCalendarActiveDay() {
  let weekCalendar = document.querySelector('.week-calendar');
  let weekDaysList = weekCalendar.querySelectorAll('.week-calendar .week-day');

  let dayToReturn;

  // return the first active day
  for (let i = 0; i < weekDaysList.length; i++) {
    if (weekDaysList[i].classList.contains('active')) {
      dayToReturn = {
        dayIndex: i,
        dayNumber: parseInt(weekDaysList[i].querySelector('.week-calendar .day-number').innerText),
        monthNumber: parseInt(weekDaysList[i].querySelector('.week-calendar .day-month').innerText),
        yearNumber: parseInt(weekDaysList[i].querySelector('.week-calendar .day-year').innerText),
        dateObj: new Date(`${weekDaysList[i].querySelector('.week-calendar .day-year').innerText}-${weekDaysList[i].querySelector('.week-calendar .day-month').innerText}-${weekDaysList[i].querySelector('.week-calendar .day-number').innerText}`)
      }
    }

  }

  return dayToReturn;
}

// ============================================================================
//
//                     DATE PICKER / MONTH CALENDAR
//
// ============================================================================

/**
 * Updates the '.date-picker' with data from the daysArrayofObjects.
 *
 * @param {Array} daysArrayofObjects - An array of objects with dayNumber, monthNumber, yearNumber as keys.
 * @return {undefined} This function does not return a value.
 */
function updateMonthCalendar(daysArrayofObjects) {
  let monthCalendar = document.querySelector('.date-picker');
  let dayNumberList = monthCalendar.querySelectorAll('.date-picker .month-day');
  let monthName = monthCalendar.querySelector('.date-picker .month-title');
  let monthNumber = monthCalendar.querySelector('.date-picker .month-number');
  let yearNumber = monthCalendar.querySelector('.date-picker .month-year');

  // Fill the .month-day with data
  for (let i = 0; i < dayNumberList.length; i++) {
    try {
      dayNumberList[i].innerText = daysArrayofObjects[i].dayNumber;
    } catch (error) {
      // It's a 5*7 square so 35 days, over 30 days many months will fail
      dayNumberList[i].innerText = "";
    }
  }

  // Fill the .month-name with italian locale long month name
  let currentJSDate = new Date(`${daysArrayofObjects[0].yearNumber}-${daysArrayofObjects[0].monthNumber}-${daysArrayofObjects[0].dayNumber}`);
  monthName.innerText = currentJSDate.toLocaleDateString('it-IT', { month: 'long' });
  monthNumber.innerText = daysArrayofObjects[0].monthNumber;
  yearNumber.innerText = daysArrayofObjects[0].yearNumber;
}


/**
 * Moves the week calendar based on the specified operation.
 *
 * @param {string} operation - The operation to perform. It can be either 'forward' or 'backward'.
 * @return {undefined} - This function does not return a value.
 */
function moveMonthCalendar(operation) {
  const datePicker = document.querySelector('.date-picker');
  const firstDay = document.querySelector('.date-picker .month-day');
  const dayNumber = firstDay.innerText;
  const monthNumber = datePicker.querySelector('.date-picker .month-number').innerText;
  const yearNumber = datePicker.querySelector('.date-picker .month-year').innerText;
  const startingDateObject = new Date(`${yearNumber}-${monthNumber}-${dayNumber}`);


  // Add or remove 1 month to the starting date
  if (operation == 'forward') {
    startingDateObject.setMonth(startingDateObject.getMonth() + 1);
  }
  if (operation == 'backward') {
    startingDateObject.setMonth(startingDateObject.getMonth() - 1);
  }


  // Check how many days there are in the month of startingDateObject
  const numberOfDaysInMonth = new Date(startingDateObject.getFullYear(), startingDateObject.getMonth() + 1, 0).getDate();

  // Generate an array of Date Objects with the amount of days the new month has
  let arrayOfDates = [];
  for (let i = 1; i <= numberOfDaysInMonth; i++) {
    arrayOfDates.push(new Date(startingDateObject.getFullYear(), startingDateObject.getMonth(), i));
  }

  // Generate an array of properly formatted JS objects with strings in Italian
  let arrayOfDateStrings = [];
  for (let i = 0; i < numberOfDaysInMonth; i++) {
    const dayStrings = {
      dayNumber: arrayOfDates[i].getDate(),
      monthNumber: arrayOfDates[i].getMonth() + 1, // Months start at 0 in js dates
      yearNumber: arrayOfDates[i].getFullYear()
    }

    arrayOfDateStrings[i] = dayStrings;
  }

  // Modify the DOM of the date picker with the new data
  updateMonthCalendar(arrayOfDateStrings);
}


/**
 * Changes the selected month day and updates active class , #dateID and #dateValue accordingly.
 *
 * @param {Event} event - The event object triggered by the user action.
 * @return {void} This function does not return a value.
 */
function changeMonthDay(event) {
  // Get the selected day
  const selectedDay = event.target.closest('.date-picker .month-day');

  if (selectedDay.innerText !== "") {
    // Remove the "active" class from all days
    const days = document.querySelectorAll('.date-picker .month-day');

    days.forEach(day => {
      day.classList.remove('active');
    });

    // Add the "active" class to the selected day
    selectedDay.classList.add('active');

    // Set input#dateID value to the selected date
    const currentDay = document.querySelector('.date-picker .month-day.active').innerText;
    const currentMonth = document.querySelector('.date-picker .month-number').innerText;
    const currentYear = document.querySelector('.date-picker .month-year').innerText;

    document.querySelector('#dateName').value = `${currentDay}/${currentMonth}/${currentYear}`;

    // This one is the hidden input to retrieve
    document.querySelector('#dateValue').value = `${currentYear}-${currentMonth}-${currentDay}`;
  }
}

function getMonthFromUser(event) {
  let fecha;
  let inputElement = document.querySelector('.date-picker #dateName');
  let errorElement = document.querySelector('.date-picker .error');

  const regexFecha = /^\d{1,2}\/\d{1,2}\/\d{4}$/;

  if (event.key === 'Enter') {
    fecha = document.querySelector('.date-picker #dateName').value;

    const [day, month, year] = fecha.split('/');

    console.log(`${day}/${month}/${year}`);

    /* Checks for formats

    dd/mm/yyyy
    d/mm/yyyy
    d/m/yyyy
    dd/m/yyyy

    */
    if (regexFecha.test(fecha)) {
      errorElement.innerText = "";
    } else {
      inputElement.value = "";
      errorElement.innerText = "Data non valida! Ricordarsi di utilizzare il formato gg/mm/aaaa.";
    }

    // Check that input day is not over the number of days of such month
    const numberOfDaysInMonth = new Date(year, month, 0).getDate();

    if (day > numberOfDaysInMonth) {
      inputElement.value = "";
      errorElement.innerText = `Data non valida! Il mese inserito ha solo ${numberOfDaysInMonth} giorni!`;
    }

    // Check that month is not over 12
    if (month > 12) {
      inputElement.value = "";
      errorElement.innerText = "Ci sono solo 12 mesi in un anno!";
    }

  }
}


function getMonthCalendarActiveDay() {
  const activeDay = document.querySelector('.date-picker .month-day.active');
  const activeMonth = document.querySelector('.date-picker .month-number');
  const activeYear = document.querySelector('.date-picker .month-year');

  const numberOfDaysInMonth = new Date(activeYear.innerText, activeMonth.innerText, 0).getDate();
  let dayToReturn;

  dayToReturn = {
    dayIndex: parseInt(activeDay.innerText) - 1,
    dayNumber: parseInt(activeDay.innerText),
    monthNumber: parseInt(activeMonth.innerText),
    yearNumber: parseInt(activeYear.innerText),
    dateObj: new Date(`${activeYear.innerText}-${activeMonth.innerText}-${activeDay.innerText}`)
  }

  return dayToReturn;

}



// ============================================================================
//
//                              INDICATOR
//
// ============================================================================

/**
 * Updates the .form-indicator number based on the given indicator element.
 *
 * To be used like onclick="updateIndicatorNumber(this)" on each button
 *
 * @param {Element} indicator - The indicator element to update the number for.
 * @return {undefined} This function does not return a value.
 */
function updateIndicatorNumber(indicator) {
  // Get the controls
  let controls = indicator.parentElement;
  let number = controls.querySelector('.number');
  let inputHiden = controls.querySelector('input');

  // if current element is .plus, add 1 to .number
  if (indicator.classList.contains('plus')) {
    number.innerText = parseInt(number.innerText) + 1;
    inputHiden.value = parseInt(number.innerText);
  }

  // if current element is .minus, subtract 1 from .number
  // do not go below 0
  if (indicator.classList.contains('minus')) {
    if (parseInt(number.innerText) > 0) {
      number.innerText = parseInt(number.innerText) - 1;
      inputHiden.value = parseInt(number.innerText);
    }
  }
}

// ============================================================================
//
//                              PROGRESS BAR
//
// ============================================================================

/**
 * Updates the '.progress-bar progress' based on the position of a click event.
 *
 * Called inline with onclick="updateProgressBar(this)"
 *
 * @param {HTMLElement} progressBar - The progress bar element to update.
 * @return {void} This function does not return anything.
 */
function updateProgressBar(progressBar) {
  const posX = event.offsetX;
  const progressBarWidth = progressBar.offsetWidth;

  // calculate which % of widht is the click positioned in
  const percent = Math.round((posX / progressBarWidth) * 100);

  // progress value property
  progressBar.value = percent;
}


// ============================================================================
//
//                              STEP INDICATOR
//
// ============================================================================


/**
 * Updates the '.step-indicator' in the UI based on the provided step.
 *
 * Assumes only one step indicator on page
 *
 * @param {number} step - The step number to update the indicator up to.
 */
function updateStepIndicator(step) {
  let stepArea = document.querySelector('.step-indicator .step-area');

  // Add .active to every .step element up until the number provied in step variable
  for (let i = 0; i < stepArea.children.length; i++) {
    if (i < step) {
      stepArea.children[i].classList.add('active');
    } else {
      stepArea.children[i].classList.remove('active');
    }
  }
}


// ============================================================================
//
//                              DROP DOWN
//
// ============================================================================

function dropDownClick(details, changeSummary = false) {
  // Get the event object
  const event = window.event;

  // Check if the clicked element is a li
  if (event.target.tagName.toLowerCase() === 'li') {
    // Find and update the input
    const input = details.querySelector('input[type="hidden"]');
    input.value = event.target.innerText;

    // Closes the details element when a li is clicked
    details.removeAttribute('open');

    // If changeSummary is true, update the summary with the innerText of the li
    if (changeSummary) {
      const summary = details.querySelector('summary');
      summary.innerText = input.value;
    }
  }
}


// ============================================================================
//
//                              Input Button
//
// ============================================================================

function switchActiveButton(event) {
  // get all child elements of 'options'
  const children = event.currentTarget.querySelector('.options').children;

  // loop through all child elements
  for (let i = 0; i < children.length; i++) {
    // if the child element is the one that was clicked, add the 'active' class
    // and add value to input:hidden
    if (children[i] == event.target) {
      children[i].classList.add('active');
      event.currentTarget.querySelector('input[type="hidden"]').value = children[i].innerText;
    }
    // if it's not the one that was clicked, remove the 'active' class
    else {
      children[i].classList.remove('active');
    }
  }
}


// ============================================================================
//
//                              Upload Form
//
// ============================================================================

function updateUploadDisplay(input) {
  // Get the file name
  const fileName = input.files[0].name;

  // Select the grandparet of the current input element
  const grandparent = input.parentElement.parentElement;

  // Set the file name
  grandparent.querySelector('.success .filename').innerText = fileName;

  // Shitch the display of the upload form
  grandparent.querySelector('.upload-area').style.display = 'none';
  grandparent.querySelector('.success').style.display = 'flex';

  // If the user clicks again on .success, reverse the display
  // Check if the .success element already has a click event listener
  const successElement = grandparent.querySelector('.success');
  const hasEventListener = successElement.onclick !== null;

  // If it doesn't have an event listener, add one
  if (!hasEventListener) {
    successElement.addEventListener('click', () => {
      grandparent.querySelector('.upload-area').style.display = 'flex';
      grandparent.querySelector('.success').style.display = 'none';
    });
  }
}


// ============================================================================
//
//                              Cards Allenamento
//
// ============================================================================

/*

    For making this cards work, you're expected first to inject the initial data
    in the DOM in the following functions:

    setAllenamentoCard()

*/
/*
let CARD_ALLENAMENTO_DATA_EXAMPLE = [
  {
    "id": 0,
    "type": "exercise",
    "exerciseName": "Exercise 0",
    "minutes": 0,
    "seconds": 30,
    "secRep": "5 repetitions",
    "weightToCarry": "0 kg",
    "typeOfCount": "count-down",
    "videoTutorial": "https://www.youtube.com/watch?v=H6DRdTPBtPs&feature=youtu.be"
  },
  {
    "id": 1,
    "type": "question",
    "questionTitle": "Did you find this exercise challenging?",
    "questionType": "boolean",
    "validAnswers": ["Example 1", "Example 2"]
  },
  {
    "id": 2,
    "type": "exercise",
    "exerciseName": "Exercise 1",
    "minutes": 5,
    "seconds": 30,
    "secRep": "5 repetitions",
    "weightToCarry": "0 kg",
    "typeOfCount": "count-down",
    "videoTutorial": "https://www.youtube.com/watch?v=H6DRdTPBtPs&feature=youtu.be"
  },
  {
    "id": 3,
    "type": "question",
    "questionTitle": "Question 1",
    "questionType": "input",
    "validAnswers": []
  },
  {
    "id": 4,
    "type": "exercise",
    "exerciseName": "Exercise 2",
    "minutes": 5,
    "seconds": 30,
    "secRep": "5 repetitions",
    "weightToCarry": "0 kg",
    "typeOfCount": "count-down",
    "videoTutorial": "https://www.youtube.com/watch?v=H6DRdTPBtPs&feature=youtu.be"
  },
  {
    "id": 5,
    "type": "question",
    "questionTitle": "Question 2",
    "questionType": "counter",
    "validAnswers": []
  },
  {
    "id": 6,
    "type": "exercise",
    "exerciseName": "Exercise 3",
    "minutes": 5,
    "seconds": 30,
    "secRep": "5 repetitions",
    "weightToCarry": "0 kg",
    "typeOfCount": "count-down",
    "videoTutorial": "https://www.youtube.com/watch?v=H6DRdTPBtPs&feature=youtu.be"
  },
  {
    "id": 7,
    "type": "question",
    "questionTitle": "Question 3",
    "questionType": "boolean",
    "validAnswers": ["Yes", "No"]
  },
  {
    "id": 8,
    "type": "exercise",
    "exerciseName": "Exercise 4",
    "minutes": 5,
    "seconds": 30,
    "secRep": "5 repetitions",
    "weightToCarry": "0 kg",
    "typeOfCount": "count-down",
    "videoTutorial": "https://www.youtube.com/watch?v=H6DRdTPBtPs&feature=youtu.be"
  },
  {
    "id": 9,
    "type": "question",
    "questionTitle": "Question 4",
    "questionType": "input",
    "validAnswers": []
  },
  {
    "id": 10,
    "type": "exercise",
    "exerciseName": "Exercise 5",
    "minutes": 5,
    "seconds": 30,
    "secRep": "5 repetitions",
    "weightToCarry": "0 kg",
    "typeOfCount": "count-up",
    "videoTutorial": "https://www.youtube.com/watch?v=H6DRdTPBtPs&feature=youtu.be"
  }
];*/

let CARD_ALLENAMENTO_STATE = {
  currentState: 'start',
  currentExerciseIndex: 0,
  currentExerciseMinutes: 0,
  currentExerciseSeconds: 0
}


function openModelPopup(url) {

  document.getElementById('iFrameName').src = url;

  jQuery(".modal.dialog-video").addClass("show");
  jQuery(".modal.dialog-video").removeClass("hide");


}
function closeModelPopup() {
  

  var src = jQuery('iframe#iFrameName').attr('src');
  jQuery('iframe#iFrameName').attr('src', '');
  jQuery('iframe#iFrameName').attr('src', src);
  
  jQuery(".modal.dialog-video").removeClass("show");
  jQuery(".modal.dialog-video").addClass("hide");
}

function closeCircutoVideo() {


  var src = jQuery('iframe#CVideo').attr('src');
  jQuery('iframe#CVideo').attr('src', '');
  jQuery('iframe#CVideo').attr('src', src);

  jQuery(".modal.dialog-circuit-video").removeClass("show");
  jQuery(".modal.dialog-circuit-video").addClass("hide");
}

/**
 * Sets the exercise cards and question cards for the Allenamento card.
 *
 * @param {Array} listOfExercises - The list of exercises to be displayed.
 */
function setAllenamentoCard(listOfExercises) {


  // Get the exercise card references from the DOM
  const currentCard = document.querySelector('.card-allenamento .exercise.current');
  const exerciseCard = document.querySelector('.card-allenamento .examples .exercise');

  // Get the question card references from the DOM
  const questionTypes = {
    boolean: document.querySelector('.card-allenamento .examples .form-input-button'),
    input: document.querySelector('.card-allenamento .examples .text-input'),
    counter: document.querySelector('.card-allenamento .examples .form-indicator'),
  }


  // Now we have to show an exercise list in the .exercise-list that will be
  // "consumed" every time we finish one of them, meaning the finished one will
  // dissapeared from the list.
  //
  // Questions won't appear either.
  for (let i = 0; i < listOfExercises.length; i++) {
    // Avoid the first index, as it will be the first currentCard
    if (i == 0) { continue; }

    // Only push exercises to the list
    if (listOfExercises[i].type == 'exercise') {
      // Create a copy of the DOM node so we don't modify the example (avoiding passing by reference)
      const newExerciseCard = exerciseCard.cloneNode(true);

      // Fill the variables for this very exercise card
      newExerciseCard.setAttribute('data-id', i);
      newExerciseCard.querySelector('.title').innerText = listOfExercises[i].exerciseName;
      newExerciseCard.querySelector('.idesercizio').innerText = listOfExercises[i].idesercizio;
      newExerciseCard.querySelector('.typeOfCount').innerText = listOfExercises[i].typeOfCount;
      newExerciseCard.querySelector('.repetitions').innerText = listOfExercises[i].secRep;
      newExerciseCard.querySelector('.kilograms').innerText = listOfExercises[i].weightToCarry;

      //newExerciseCard.querySelector('.video-link').innerHTML = "<div class='button-default-no-icon' onclick='openModelPopup(&quot;" + listOfExercises[i].videoTutorial + "&quot;)'>Guarda il video tutorial456 </div>";
      if (listOfExercises[i].videoTutorial)
        newExerciseCard.querySelector('.video-link').innerHTML = "<a href='javascript:;' class='button-default-no-icon hide' onclick='openModelPopup(&quot;" + listOfExercises[i].videoTutorial + "&quot;)'>Guarda il video tutorial</a>";
      //newExerciseCard.querySelector('.video-link a').href = listOfExercises[i].videoTutorial;

      // Append the new card to the exercise list
      document.querySelector('.card-allenamento .exercise-list').appendChild(newExerciseCard);
    }
  }

  // Now that we appended all the exercises to the list, we have to set the first
  // exercise in the currentCard (the one that shows below the clock).
  //
  // This card is different because it has buttons and other stuff.
  currentCard.setAttribute('data-id', 0);
  currentCard.querySelector('.title').innerText = listOfExercises[0].exerciseName;
  currentCard.querySelector('.idesercizio').innerText = listOfExercises[0].idesercizio;
  currentCard.querySelector('.typeOfCount').innerText = listOfExercises[0].typeOfCount;
  currentCard.querySelector('.repetitions').innerText = listOfExercises[0].secRep;
  currentCard.querySelector('.kilograms').innerText = listOfExercises[0].weightToCarry;
  //currentCard.querySelector('.video-link a').href = listOfExercises[0].videoTutorial;

  if (listOfExercises[0].videoTutorial)
    currentCard.querySelector('.video-link').innerHTML = "<a href='javascript:;' class='button-default-no-icon hide' onclick='openModelPopup(&quot;" + listOfExercises[0].videoTutorial + "&quot;)'>Guarda il video tutorial</a>";
  else
    currentCard.querySelector('.video-link').innerHTML = "";

  //currentCard.querySelector('.video-link').innerHTML = "<div class='button' onclick='openModelPopup(&quot;" + listOfExercises[0].videoTutorial + "&quot;)'>Guarda il video tutorial456 </div>";
  currentCard.querySelector('.sets .string').innerText = `1 giro di ${listOfExercises.length}`;

  // Now we have to change the global state and call the main loop to do its thing
  // We also pass the question types.
  //
  // Question types are defined in this FN and not in a global variable because why not.
  CARD_ALLENAMENTO_STATE.currentState = 'start';
  mainAllenamentoCardLoop(listOfExercises, questionTypes);
}

function mainAllenamentoCardLoop(listOfExercises, questionTypes) {
  // Set the event listeners for the currentCard buttons.
  //
  // listenButtonClicks() defines what the global state of the card will be
  // when a button is clicked. All the logic is handled there. Not pretty, but
  // it works.
  //
  // Allowed states are:
  //         - start: Exercise has not been started yet
  //         - preparedToRun: The main loop has to start the clock
  //         - running: The clock is running, either by starting or resuming
  //         - paused: User pressed the pause button
  //         - finished: The clock finished or user pressed conclude (Also questions are in this state)
  //         - nextExercise: User pressed avanti
  //         - end: The user went through all the exercises
  //
  listenButtonClicks();

  // Some vars defined here to avoid block scope
  let cardCurrentState;
  let currentExerciseIndex;
  const numberOfExercises = listOfExercises.length;


  // Implementing this logic here so we don't write it twice in the main loop
  function loadNextExercise() {


    // Set the current exercise index to +1
    CARD_ALLENAMENTO_STATE.currentExerciseIndex++;
    currentExerciseIndex++;

    // Now that we updated the index, Set the new current Index exercise as currentCard
    // (The card below the clock)
    try {
      const currentCard = document.querySelector('.card-allenamento .exercise.current');
      currentCard.setAttribute('data-id', currentExerciseIndex);
      currentCard.querySelector('.title').innerText = listOfExercises[currentExerciseIndex].exerciseName;
      currentCard.querySelector('.idesercizio').innerText = listOfExercises[currentExerciseIndex].idesercizio;
      currentCard.querySelector('.typeOfCount').innerText = listOfExercises[currentExerciseIndex].typeOfCount;
      currentCard.querySelector('.repetitions').innerText = listOfExercises[currentExerciseIndex].secRep;
      currentCard.querySelector('.kilograms').innerText = listOfExercises[currentExerciseIndex].weightToCarry;
      if (listOfExercises[currentExerciseIndex].videoTutorial)
        currentCard.querySelector('.video-link a').href = listOfExercises[currentExerciseIndex].videoTutorial;

      currentCard.querySelector('.sets .string').innerText = `1 giro di ${listOfExercises.length}`;
    } catch (error) {
      console.error(error);
    }

    // Now, the exercise that went below the clock, we want to hide from the exercise
    // list, so it doesn't appear twice for the user, and we produce this effect of
    // "consuming" the exercises.
    try {
      const exerciseList = document.querySelector('.card-allenamento .exercise-list');
      const previousExercise = exerciseList.querySelector(`[data-id='${currentExerciseIndex}']`);
      previousExercise.style.display = 'none';
    } catch (error) {
      console.error(`The exercise is not on the list, or it's a question, cannot hide it:\n${error}`);
    }

    // Finally, we change state to start, so the loop will show the starting button
    CARD_ALLENAMENTO_STATE.currentState = 'start';
  }

  // Now we need a main loop that checks the global state and decides what to
  // do with such state. This is a messy solution, but I know no better.
  //
  // We can't use a while loop because it freezes the browser, so we define a
  // function inside this very one and call it recursively with setTimeout().
  //
  function mainLoop() {

    // Checks the current state in the global variable.
    //
    // Some code may be unnecessary, but this fn is very long, so helps with
    // clarity.
    //
    // This is the bread & butter of this function, we will use it a lot.
    cardCurrentState = CARD_ALLENAMENTO_STATE.currentState;
    currentExerciseIndex = CARD_ALLENAMENTO_STATE.currentExerciseIndex;
    isAnExercise = listOfExercises[currentExerciseIndex].type == 'exercise';
    isAQuestion = listOfExercises[currentExerciseIndex].type == 'question';

    // Before defining behaviors based on the current state, we need to check
    // if we're in an exercise or a question, as such behaviors will differ
    // based on that
    if (isAnExercise) {
      // Loop logic for exercises
      switch (cardCurrentState) {
        // The start state defines de card when is not yet running the clock
        // So user need to press the start button, and he has to see the
        // remaining time before the clock starts ticking.
        case 'start':
          // Show the INIZIA button
          changeAllenamentoCardButtons('inizio');

          // Set the clock timer with the current exercise
          const minutesAndSecondsString = `${listOfExercises[currentExerciseIndex].minutes}:${listOfExercises[currentExerciseIndex].seconds}`;
          setCircularProgressDOM(0, minutesAndSecondsString);

          break;

        // The preparedToRun state defines the card when the user pressed
        // the start button, so we need to get things going, and update
        // the state to running when everything is ready.
        case 'preparedToRun':
          // Show the PAUSA button
          changeAllenamentoCardButtons('esercizio-iniziato');

          // Start the clock with the time of the current exercise
          // Check if it's count-down or count up before starting
          const typeOfCount = listOfExercises[currentExerciseIndex].typeOfCount;

          // Get the current exercise total time
          const minutesAndSecondsObject = {
            minutes: listOfExercises[currentExerciseIndex].minutes,
            seconds: listOfExercises[currentExerciseIndex].seconds
          }

          if (typeOfCount == 'count-down') {
            // Call the circular count down function
            circularCountDownTimer(minutesAndSecondsObject);
          }

          if (typeOfCount == 'count-up') {
            // Call the circular count up function
            circularCountUpTimer();
          }

          // Set the state to running
          CARD_ALLENAMENTO_STATE.currentState = 'running';

          break;

        // The running state defines the card when the clock is running
        // We shouldn't do anything in this state but we're swopping the
        // pausa button just in case the logic faild somewhere else.
        case 'running':
          // Show the PAUSA button
          changeAllenamentoCardButtons('esercizio-iniziato');

          // The clock function will resume automatically when state is running
          break;

        // The user pressed the pause button. Clock function will handle itself
        // but button logic should be handled here
        case 'paused':
          // Show the RIPRENDI button
          changeAllenamentoCardButtons('timer-in-pausa');

          // The clock function will freeze when paused, no need for sending input

          break;

        // The user pressed the conclude button. Clock function will handle itself,
        // but we have to show the user the button to go to next exercise.
        //
        // It's easy to think that here we should define whant happens next, but
        // maybe the user wants to wait before next exercise is ready to start,
        // so for getting things going we have an extra state.
        case 'finished':
          // Show the AVANTI button
          changeAllenamentoCardButtons('timer-concluso');
          break;

        // Now the user pressed the next button. We have to handle a ton of logic
        // and set the state to start afterwards.
        case 'nextExercise':
          loadNextExercise();
      }
    }

    if (isAQuestion) {

      /* Questions don't have the same logic as exercises. We only have to
         show the next button, handle the user answer and change the state.

         The user response will be injected in the corresponting question
         in listOfExercises array, so we can return the array when the
         loop finishes, containing all the answers.
      */
      switch (cardCurrentState) {
        // Previous card defined this state, so we use it to load the
        // question and put it in the current card.
        case 'start':
          // We need to gather whant kind of question this is
          const questionType = listOfExercises[currentExerciseIndex].questionType;

          // Now we load the question tipe from the questionTypes object
          // We create a copy so we don't modify the example
          // (avoiding passing by reference)
          const newQuestion = questionTypes[questionType].cloneNode(true);

          // Now fill the question node with listOfExercises[ourQuestionIndex]
          newQuestion.setAttribute('data-id', currentExerciseIndex);
          newQuestion.querySelector('.title').innerText = listOfExercises[currentExerciseIndex].questionTitle;

          if (questionType == 'boolean') {
            newQuestion.querySelector('.options .si').innerText = listOfExercises[currentExerciseIndex].validAnswers[0];
            newQuestion.querySelector('.options .no').innerText = listOfExercises[currentExerciseIndex].validAnswers[1];
          }

          // Now we want to append the question just above the
          // currentCard buttons, and below the exercise data
          document.querySelector('.card-allenamento .exercise.current .questions').appendChild(newQuestion);

          // But we also want to show the previous exercise name, and hide:
          // .info .repetitions,
          // .info .kilograms,
          // .video-link a
          // .sets .string
          document.querySelector('.card-allenamento .exercise.current .title').innerText = listOfExercises[currentExerciseIndex].exerciseName;
          document.querySelector('.card-allenamento .exercise.current .info .repetitions').innerText = '';
          document.querySelector('.card-allenamento .exercise.current .info .kilograms').innerText = '';
          document.querySelector('.card-allenamento .exercise.current .video-link a').innerText = '';
          document.querySelector('.card-allenamento .exercise.current .sets .string').innerText = '';

          // Because questions have not the same logic as exercises,
          // we neet to put the state to finish and show only the
          // avanti button
          CARD_ALLENAMENTO_STATE.currentState = 'finish';
          changeAllenamentoCardButtons('timer-concluso');

          break;

        // The user pressed the next button.
        /*
            We have to push the user answer to listOfExercises array,
            remove the current question from .exercise.current .questions

            Run loadNextExercise();
        */
        case 'nextExercise':
          // Get the user answer to the question
          let questionAnswer;

          try {
            questionAnswer = document.querySelector('.card-allenamento .exercise.current .questions input').value;
          } catch (error) {
            console.error(error);
            questionAnswer = null;
          }

          // Push the answer to its corresponting question
          listOfExercises[currentExerciseIndex].userAnswer = questionAnswer;

          // Remove the question from .exercise.current .questions
          document.querySelector('.card-allenamento .exercise.current .questions .question').remove();

          loadNextExercise();
          break;
      }
    }

    // Check if there are more exercises to process
    if (currentExerciseIndex < numberOfExercises) {
      // Schedule the next iteration after a delay (in milisecons)
      //console.log("Card Allenamento loop running");
      //setTimeout(mainLoop, 200);
      setTimeout(mainLoop, 500);
    } else {
      //console.log("Card Allenamento loop finished");

      // Hide the current card
      document.querySelector('.card-allenamento .exercise.current').style.display = 'none';

      // Set global state to end
      CARD_ALLENAMENTO_STATE.currentState = 'end';

      jQuery("#btnSendFeedback").removeClass("disabled");
      //document.querySelector('#btnSendFeedback').removeClass("disabled");

      // return the list of exercises with the user answers
      return listOfExercises;
    }
  }

  // Start the main loop
  mainLoop();

}

/**
 * Listens for button clicks and updates the state and DOM accordingly.
 *
 * @param {type} buttons - the buttons to listen for click events
 * @return {type} undefined
 */
function listenButtonClicks() {


  const buttons = document.querySelectorAll('.card-allenamento .buttons button');
  let buttonText;

  // Set up event listeners for each button
  buttons.forEach(button => {
    button.addEventListener('click', () => {
      // On button click, update state and DOM

      buttonText = button.innerText;
      // Get the parent div element
      const parentDiv = button.parentElement;
      // Get the class list of the parent div
      const parentDivClassList = parentDiv.classList;

      if (parentDivClassList.contains('inizio')) {
        // Set state to preparedToRun so main loops does its thing
        CARD_ALLENAMENTO_STATE.currentState = 'preparedToRun';
      }

      if (parentDivClassList.contains('esercizio-iniziato')) {
        // + Pausa puts on pause
        if (buttonText.toLowerCase().includes('pausa')) {
          // Set state to paused
          CARD_ALLENAMENTO_STATE.currentState = 'paused';
        }

        // Concludi puts on finished
        if (buttonText.toLowerCase().includes('concludi')) {
          // Set state to finished
          CARD_ALLENAMENTO_STATE.currentState = 'finished';
        }
      }

      if (parentDivClassList.contains('timer-in-pausa')) {
        // Riprendi puts on running again
        if (buttonText.toLowerCase().includes('riprendi')) {
          // Set state to running
          CARD_ALLENAMENTO_STATE.currentState = 'running';
        }

        // Concludi puts on finished
        if (buttonText.toLowerCase().includes('concludi')) {
          // Set state to finished
          CARD_ALLENAMENTO_STATE.currentState = 'finished';
        }
      }

      if (parentDivClassList.contains('timer-concluso')) {
        // Set state to finished
        CARD_ALLENAMENTO_STATE.currentState = 'nextExercise';
      }
    });
  });
}

/**
 * Changes the buttons on the Allenamento card.
 *
 * @param {string} type - The type of buttons to change. Defaults to 'inizio'.
 * @param {function} callback - The callback function to execute. Defaults to empty arrow function.
 */
function changeAllenamentoCardButtons(type = 'inizio', callback = () => { }) {


  try {
    callback();
    let finalclass = "." + type.replace(" ", ".")
    showHideElementsByClass(finalclass);
  } catch (error) {
    console.error(error);
  }
}

/**
 * Shows or hides elements with the specified class name.
 *
 * @param {string} className - The class name of the elements to show or hide.
 */
function showHideElementsByClass(className) {
  try {
    const toHide = document.querySelectorAll(".card-allenamento .buttons > div");
    toHide.forEach(element => element.style.display = 'none');

    const toShow = document.querySelector(".card-allenamento .buttons " + className);
    toShow.style.display = 'flex';
  } catch (error) {
    console.error(error);
  }
}


/**
 * Sets the circular progress of a clock based on the given percentage and time left.
 *
 * @param {number} percent - The percentage value of the progress.
 * @param {string} timeLeft - The time left to display on the clock.
 */
function setCircularProgressDOM(percent, timeLeft) {
  /*
  Mostly copied from: https://codepen.io/jeremenichelli/pen/vegymB
  */
  try {
    const circle = document.querySelector('.card-allenamento .clock .progress-ring circle');
    const text = document.querySelector('.card-allenamento .clock .progress-ring text');
    const radius = circle.r.baseVal.value;
    const circumference = radius * 2 * Math.PI;

    circle.style.strokeDasharray = `${circumference} ${circumference}`;
    circle.style.strokeDashoffset = `${circumference}`;
    const offset = circumference - percent / 100 * circumference;
    circle.style.strokeDashoffset = offset;
    text.textContent = timeLeft;

  } catch (error) {
    console.error(error);
  }
}

/**
 * Initializes a circular countdown timer.
 *
 * @param {Object} timeObject - An object containing the minutes and seconds for the timer.
 * @param {number} timeObject.minutes - The number of minutes for the timer.
 * @param {number} timeObject.seconds - The number of seconds for the timer.
 * @return {undefined} This function does not return a value.
 */
function circularCountDownTimer(timeObject) {
  let remainingTime = timeObject.minutes * 60 + timeObject.seconds; // Convert minutes to seconds and add seconds
  let timePercentage = 100; // Initialize time percentage

  const interval = setInterval(() => {
    // Clock shouldn't be modified if it's paused
    const currentState = CARD_ALLENAMENTO_STATE.currentState;

    if (currentState != 'paused') {
      remainingTime--; // Subtract one second from the remaining time

      // Calculate the percentage of remaining time and round it to an integer
      timePercentage = Math.round((remainingTime / (timeObject.minutes * 60 + timeObject.seconds)) * 100);

      // Format the remaining time as "minutes:seconds"
      const minutes = Math.floor(remainingTime / 60);
      const seconds = remainingTime % 60;
      const formattedTime = `${minutes}:${seconds.toString().padStart(2, '0')}`;

      // Update the minutes and seconds in the global var
      CARD_ALLENAMENTO_STATE.currentExerciseMinutes = minutes;
      CARD_ALLENAMENTO_STATE.currentExerciseSeconds = seconds;

      // Update the progress bar
      setCircularProgressDOM(timePercentage, formattedTime);

      if (remainingTime <= 0 || currentState == 'finished') {
        // Stop the clock
        clearInterval(interval);
        // If global state is not finished, set to finished
        if (currentState != 'finished') {
          CARD_ALLENAMENTO_STATE.currentState = 'finished';
        }
      }
    }
  }, 1000); // Execute every second (1000 milliseconds)
}

/**
 * Initializes a circular count-up timer that updates a progress bar every second.
 *
 * @param {None} None - This function does not take any parameters.
 * @return {None} None - This function does not return anything.
 */
function circularCountUpTimer() {
  let seconds = 0;
  const interval = setInterval(() => {

    // Clock shouldn't be modified if it's paused
    const currentState = CARD_ALLENAMENTO_STATE.currentState;

    if (currentState != 'paused') {
      seconds++;
      const minutes = Math.floor(seconds / 60);
      const passedSeconds = seconds % 60;
      const passedTimeString = `${minutes}:${passedSeconds.toString().padStart(2, '0')}`;
      // Update the progress bar
      setCircularProgressDOM(0, passedTimeString);

      if (currentState == 'finished') {

        clearInterval(interval);
      }
    }

  }, 1000); // Execute every second (1000 milliseconds)
}

// ============================================================================
//
//                              Meal Compositor
//
// ============================================================================

/*

    For making this cards work, you're expected first to inject the initial data
    in the DOM in the following functions:

    setMealCompositor()

*/

const MEAL_COMPOSITOR_EXAMPLE = [
  {
    "idalimento": 1,
    "nome": "Riso Basmati",
    "c": "0.7700",
    "p": "0.0800",
    "f": "0.0090",
    "g": null,
    "percentualeC": "0.6614",
    "percentualeP": "0.0687",
    "percentualeF": "0.0077",
    "percentualeG": null,
    "minQ-gr": "50.0000",
    "maxQ-gr": "400.0000",
    "round-g": "10",
    "unit": "1",
    "group": "cereali",
    "label": "Glucidico",
    "colazione": "VERO",
    "spuntini": "VERO",
    "pranzo": "VERO",
    "cena": "VERO",
    "custom": "",
    "si-no": "VERO",
    "immagine": "/assets/img/food-icon/rice.svg"
  },
  {
    "idalimento": 2,
    "nome": "Avena",
    "c": "0.6000",
    "p": "0.1700",
    "f": "0.0010",
    "g": null,
    "percentualeC": "0.4626",
    "percentualeP": "0.1311",
    "percentualeF": "0.0008",
    "percentualeG": null,
    "minQ-gr": "20.0000",
    "maxQ-gr": "100.0000",
    "round-g": "10",
    "unit": "1",
    "group": "cereali",
    "label": "Glucidico",
    "colazione": "VERO",
    "spuntini": "VERO",
    "pranzo": "VERO",
    "cena": "VERO",
    "custom": "",
    "si-no": "VERO",
    "immagine": "/assets/img/food-icon/oat.svg"
  },
  {
    "idalimento": 3,
    "nome": "Pasta integrale",
    "c": "0.7500",
    "p": "0.1300",
    "f": "0.0010",
    "g": null,
    "percentualeC": "0.6608",
    "percentualeP": "0.1145",
    "percentualeF": "0.0009",
    "percentualeG": null,
    "minQ-gr": "50.0000",
    "maxQ-gr": "400.0000",
    "round-g": "10",
    "unit": "1",
    "group": "cereali",
    "label": "Glucidico",
    "colazione": "VERO",
    "spuntini": "VERO",
    "pranzo": "VERO",
    "cena": "VERO",
    "custom": "",
    "si-no": "VERO",
    "immagine": "/assets/img/food-icon/pasta-integrale.svg"
  },
  {
    "idalimento": 4,
    "nome": "Pasta\/ riso",
    "c": "0.7500",
    "p": "0.1000",
    "f": "0.0010",
    "g": null,
    "percentualeC": "0.6383",
    "percentualeP": "0.0851",
    "percentualeF": "0.0009",
    "percentualeG": null,
    "minQ-gr": "50.0000",
    "maxQ-gr": "400.0000",
    "round-g": "10",
    "unit": "1",
    "group": "cereali",
    "label": "Glucidico",
    "colazione": "VERO",
    "spuntini": "VERO",
    "pranzo": "VERO",
    "cena": "VERO",
    "custom": "",
    "si-no": "VERO",
    "immagine": "/assets/img/food-icon/pasta-riso.svg"
  },
  {
    "idalimento": 5,
    "nome": "Pane di segale",
    "c": "0.3500",
    "p": "0.0800",
    "f": "0.0010",
    "g": null,
    "percentualeC": "0.1509",
    "percentualeP": "0.0345",
    "percentualeF": "0.0004",
    "percentualeG": null,
    "minQ-gr": "20.0000",
    "maxQ-gr": "400.0000",
    "round-g": "10",
    "unit": "1",
    "group": "prodotti forno",
    "label": "Glucidico",
    "colazione": "VERO",
    "spuntini": "VERO",
    "pranzo": "VERO",
    "cena": "VERO",
    "custom": "",
    "si-no": "VERO",
    "immagine": "/assets/img/food-icon/pane-segale.svg"
  },
  {
    "idalimento": 6,
    "nome": "Pane integrale",
    "c": "0.5000",
    "p": "0.1000",
    "f": "0.0010",
    "g": null,
    "percentualeC": "0.3005",
    "percentualeP": "0.0601",
    "percentualeF": "0.0006",
    "percentualeG": null,
    "minQ-gr": "20.0000",
    "maxQ-gr": "400.0000",
    "round-g": "20",
    "unit": "1",
    "group": "prodotti forno",
    "label": "Glucidico",
    "colazione": "VERO",
    "spuntini": "VERO",
    "pranzo": "VERO",
    "cena": "VERO",
    "custom": "",
    "si-no": "VERO",
    "immagine": "/assets/img/food-icon/pane-integrale.svg"
  },
  {
    "idalimento": 7,
    "nome": "Legumi\/cereali cotti",
    "c": "0.2500",
    "p": "0.0800",
    "f": "0.0010",
    "g": null,
    "percentualeC": "0.0828",
    "percentualeP": "0.0265",
    "percentualeF": "0.0003",
    "percentualeG": null,
    "minQ-gr": "50.0000",
    "maxQ-gr": "400.0000",
    "round-g": "20",
    "unit": "1",
    "group": "legumi",
    "label": "Glucidico",
    "colazione": "VERO",
    "spuntini": "VERO",
    "pranzo": "VERO",
    "cena": "VERO",
    "custom": "",
    "si-no": "VERO",
    "immagine": "/assets/img/food-icon/legume-1.svg"
  },
  {
    "idalimento": 8,
    "nome": "Legumi cotti",
    "c": "0.2000",
    "p": "0.0900",
    "f": "0.0010",
    "g": null,
    "percentualeC": "0.0582",
    "percentualeP": "0.0262",
    "percentualeF": "0.0003",
    "percentualeG": null,
    "minQ-gr": "50.0000",
    "maxQ-gr": "400.0000",
    "round-g": "10",
    "unit": "1",
    "group": "legumi",
    "label": "Glucoproteico",
    "colazione": "VERO",
    "spuntini": "VERO",
    "pranzo": "VERO",
    "cena": "VERO",
    "custom": "",
    "si-no": "VERO",
    "immagine": "/assets/img/food-icon/legume.svg"
  },
  {
    "idalimento": 9,
    "nome": "Cereali cotti",
    "c": "0.3000",
    "p": "0.0800",
    "f": "0.0010",
    "g": null,
    "percentualeC": "0.1143",
    "percentualeP": "0.0305",
    "percentualeF": "0.0004",
    "percentualeG": null,
    "minQ-gr": "50.0000",
    "maxQ-gr": "400.0000",
    "round-g": "10",
    "unit": "1",
    "group": "legumi",
    "label": "Glucidico",
    "colazione": "VERO",
    "spuntini": "VERO",
    "pranzo": "VERO",
    "cena": "VERO",
    "custom": "",
    "si-no": "VERO",
    "immagine": "/assets/img/food-icon/cereali.svg"
  },
  {
    "idalimento": 10,
    "nome": "Gallette riso\/ mais\/ wasa",
    "c": "0.8000",
    "p": "0.0700",
    "f": "0.0010",
    "g": null,
    "percentualeC": "0.6968",
    "percentualeP": "0.0610",
    "percentualeF": "0.0009",
    "percentualeG": null,
    "minQ-gr": "12.0000",
    "maxQ-gr": "60.0000",
    "round-g": "8",
    "unit": "8",
    "group": "cereali",
    "label": "Glucidico",
    "colazione": "VERO",
    "spuntini": "VERO",
    "pranzo": "VERO",
    "cena": "VERO",
    "custom": "",
    "si-no": "VERO",
    "immagine": "/assets/img/food-icon/gallette.svg"
  },
  {
    "idalimento": 11,
    "nome": "Patate",
    "c": "0.2000",
    "p": "0.0170",
    "f": "0.0010",
    "g": null,
    "percentualeC": "0.0436",
    "percentualeP": "0.0037",
    "percentualeF": "0.0002",
    "percentualeG": null,
    "minQ-gr": "50.0000",
    "maxQ-gr": "800.0000",
    "round-g": "50",
    "unit": "1",
    "group": "patata",
    "label": "Glucidico",
    "colazione": "VERO",
    "spuntini": "VERO",
    "pranzo": "VERO",
    "cena": "VERO",
    "custom": "",
    "si-no": "VERO",
    "immagine": "/assets/img/food-icon/patate.svg"
  },
  {
    "idalimento": 12,
    "nome": "Patate dolci",
    "c": "0.2000",
    "p": "0.0150",
    "f": "0.0010",
    "g": null,
    "percentualeC": "0.0432",
    "percentualeP": "0.0032",
    "percentualeF": "0.0002",
    "percentualeG": null,
    "minQ-gr": "50.0000",
    "maxQ-gr": "800.0000",
    "round-g": "50",
    "unit": "1",
    "group": "patata",
    "label": "Glucidico",
    "colazione": "VERO",
    "spuntini": "VERO",
    "pranzo": "VERO",
    "cena": "VERO",
    "custom": "",
    "si-no": "VERO",
    "immagine": "/assets/img/food-icon/patate-dolci.svg"
  },
  {
    "idalimento": 13,
    "nome": "Frutta",
    "c": "0.1000",
    "p": "0.0100",
    "f": "0.0010",
    "g": null,
    "percentualeC": "0.0111",
    "percentualeP": "0.0011",
    "percentualeF": "0.0001",
    "percentualeG": null,
    "minQ-gr": "150.0000",
    "maxQ-gr": "300.0000",
    "round-g": "50",
    "unit": "1",
    "group": "frutta",
    "label": "Glucidico",
    "colazione": "VERO",
    "spuntini": "VERO",
    "pranzo": "VERO",
    "cena": "VERO",
    "custom": "",
    "si-no": "VERO",
    "immagine": "/assets/img/food-icon/frutta.svg"
  },
  {
    "idalimento": 14,
    "nome": "Marmellata",
    "c": "0.5000",
    "p": "0.0100",
    "f": "0.0010",
    "g": null,
    "percentualeC": "0.2555",
    "percentualeP": "0.0051",
    "percentualeF": "0.0005",
    "percentualeG": null,
    "minQ-gr": "20.0000",
    "maxQ-gr": "100.0000",
    "round-g": "10",
    "unit": "1",
    "group": "frutta",
    "label": "Glucidico",
    "colazione": "VERO",
    "spuntini": "VERO",
    "pranzo": "VERO",
    "cena": "VERO",
    "custom": "",
    "si-no": "VERO",
    "immagine": "/assets/img/food-icon/marmellata.svg"
  },
  {
    "idalimento": 15,
    "nome": "Verdure \/ ortaggi",
    "c": "0.0500",
    "p": "0.0050",
    "f": "0.0010",
    "g": null,
    "percentualeC": "0.0028",
    "percentualeP": "0.0003",
    "percentualeF": "0.0001",
    "percentualeG": null,
    "minQ-gr": "50.0000",
    "maxQ-gr": "400.0000",
    "round-g": "50",
    "unit": "1",
    "group": "verdura",
    "label": "Glucidico",
    "colazione": "VERO",
    "spuntini": "VERO",
    "pranzo": "VERO",
    "cena": "VERO",
    "custom": "",
    "si-no": "VERO",
    "immagine": "/assets/img/food-icon/verdure-ortaggi.svg"
  },
  {
    "idalimento": 16,
    "nome": "Verdure a foglia",
    "c": "0.0300",
    "p": "0.0050",
    "f": "0.0010",
    "g": null,
    "percentualeC": "0.0011",
    "percentualeP": "0.0002",
    "percentualeF": "0.0000",
    "percentualeG": null,
    "minQ-gr": "50.0000",
    "maxQ-gr": "400.0000",
    "round-g": "50",
    "unit": "1",
    "group": "verdura",
    "label": "Glucidico",
    "colazione": "VERO",
    "spuntini": "VERO",
    "pranzo": "VERO",
    "cena": "VERO",
    "custom": "",
    "si-no": "VERO",
    "immagine": "/assets/img/food-icon/verdure-foglia.svg"
  },
  {
    "idalimento": 17,
    "nome": "Muesli",
    "c": "0.6000",
    "p": "0.1000",
    "f": "0.0010",
    "g": null,
    "percentualeC": "0.4206",
    "percentualeP": "0.0701",
    "percentualeF": "0.0007",
    "percentualeG": null,
    "minQ-gr": "30.0000",
    "maxQ-gr": "60.0000",
    "round-g": "10",
    "unit": "1",
    "group": "cereali",
    "label": "Glucidico",
    "colazione": "VERO",
    "spuntini": "VERO",
    "pranzo": "VERO",
    "cena": "VERO",
    "custom": "",
    "si-no": "VERO",
    "immagine": "/assets/img/food-icon/muesli.svg"
  },
  {
    "idalimento": 18,
    "nome": "Fette biscottate",
    "c": "0.6000",
    "p": "0.1000",
    "f": "0.0010",
    "g": null,
    "percentualeC": "0.4206",
    "percentualeP": "0.0701",
    "percentualeF": "0.0007",
    "percentualeG": null,
    "minQ-gr": "8.0000",
    "maxQ-gr": "56.0000",
    "round-g": "10",
    "unit": "10",
    "group": "prodotti forno",
    "label": "Glucidico",
    "colazione": "VERO",
    "spuntini": "VERO",
    "pranzo": "VERO",
    "cena": "VERO",
    "custom": "",
    "si-no": "VERO",
    "immagine": "/assets/img/food-icon/fette-biscottate.svg"
  },
  {
    "idalimento": 19,
    "nome": "Carne magra",
    "c": "0.0010",
    "p": "0.2000",
    "f": "0.0200",
    "g": null,
    "percentualeC": "0.0002",
    "percentualeP": "0.0442",
    "percentualeF": "0.0044",
    "percentualeG": null,
    "minQ-gr": "50.0000",
    "maxQ-gr": "350.0000",
    "round-g": "50",
    "unit": "1",
    "group": "carne",
    "label": "Proteico",
    "colazione": "VERO",
    "spuntini": "VERO",
    "pranzo": "VERO",
    "cena": "VERO",
    "custom": "",
    "si-no": "VERO",
    "immagine": "/assets/img/food-icon/carne-magra.svg"
  },
  {
    "idalimento": 20,
    "nome": "Pesce magro",
    "c": "0.0010",
    "p": "0.2000",
    "f": "0.0200",
    "g": null,
    "percentualeC": "0.0002",
    "percentualeP": "0.0442",
    "percentualeF": "0.0044",
    "percentualeG": null,
    "minQ-gr": "50.0000",
    "maxQ-gr": "350.0000",
    "round-g": "10",
    "unit": "1",
    "group": "pesce",
    "label": "Proteico",
    "colazione": "VERO",
    "spuntini": "VERO",
    "pranzo": "VERO",
    "cena": "VERO",
    "custom": "",
    "si-no": "VERO",
    "immagine": "/assets/img/food-icon/pesce-magro.svg"
  },
  {
    "idalimento": 21,
    "nome": "Carne semigrassa",
    "c": "0.0010",
    "p": "0.2000",
    "f": "0.0500",
    "g": null,
    "percentualeC": "0.0003",
    "percentualeP": "0.0502",
    "percentualeF": "0.0126",
    "percentualeG": null,
    "minQ-gr": "50.0000",
    "maxQ-gr": "350.0000",
    "round-g": "10",
    "unit": "1",
    "group": "carne",
    "label": "Proteico",
    "colazione": "VERO",
    "spuntini": "VERO",
    "pranzo": "VERO",
    "cena": "VERO",
    "custom": "",
    "si-no": "VERO",
    "immagine": "/assets/img/food-icon/carne-semigrassa.svg"
  },
  {
    "idalimento": 22,
    "nome": "Pesce semigrasso",
    "c": "0.0010",
    "p": "0.2000",
    "f": "0.0500",
    "g": null,
    "percentualeC": "0.0003",
    "percentualeP": "0.0502",
    "percentualeF": "0.0126",
    "percentualeG": null,
    "minQ-gr": "50.0000",
    "maxQ-gr": "400.0000",
    "round-g": "10",
    "unit": "1",
    "group": "pesce",
    "label": "Proteico",
    "colazione": "VERO",
    "spuntini": "VERO",
    "pranzo": "VERO",
    "cena": "VERO",
    "custom": "",
    "si-no": "VERO",
    "immagine": "/assets/img/food-icon/pesce-semigrasso.svg"
  },
  {
    "idalimento": 23,
    "nome": "Affettato magro",
    "c": "0.0010",
    "p": "0.3000",
    "f": "0.0300",
    "g": null,
    "percentualeC": "0.0003",
    "percentualeP": "0.0993",
    "percentualeF": "0.0099",
    "percentualeG": null,
    "minQ-gr": "50.0000",
    "maxQ-gr": "250.0000",
    "round-g": "10",
    "unit": "1",
    "group": "carne",
    "label": "Proteico",
    "colazione": "VERO",
    "spuntini": "VERO",
    "pranzo": "VERO",
    "cena": "VERO",
    "custom": "",
    "si-no": "VERO",
    "immagine": "/assets/img/food-icon/affettato-magro.svg"
  },
  {
    "idalimento": 24,
    "nome": "Affettato semigrasso",
    "c": "0.0010",
    "p": "0.2500",
    "f": "0.0800",
    "g": null,
    "percentualeC": "0.0003",
    "percentualeP": "0.0828",
    "percentualeF": "0.0265",
    "percentualeG": null,
    "minQ-gr": "50.0000",
    "maxQ-gr": "250.0000",
    "round-g": "10",
    "unit": "1",
    "group": "carne",
    "label": "Proteico",
    "colazione": "VERO",
    "spuntini": "VERO",
    "pranzo": "VERO",
    "cena": "VERO",
    "custom": "",
    "si-no": "VERO",
    "immagine": "/assets/img/food-icon/affettato-semigrasso.svg"
  },
  {
    "idalimento": 25,
    "nome": "Tonno naturale",
    "c": "0.0010",
    "p": "0.2500",
    "f": "0.0030",
    "g": null,
    "percentualeC": "0.0003",
    "percentualeP": "0.0635",
    "percentualeF": "0.0008",
    "percentualeG": null,
    "minQ-gr": "56.0000",
    "maxQ-gr": "280.0000",
    "round-g": "10",
    "unit": "1",
    "group": "pesce",
    "label": "Proteico",
    "colazione": "VERO",
    "spuntini": "VERO",
    "pranzo": "VERO",
    "cena": "VERO",
    "custom": "",
    "si-no": "VERO",
    "immagine": "/assets/img/food-icon/tonno.svg"
  },
  {
    "idalimento": 26,
    "nome": "Uova",
    "c": "0.0010",
    "p": "0.1200",
    "f": "0.1000",
    "g": null,
    "percentualeC": "0.0002",
    "percentualeP": "0.0265",
    "percentualeF": "0.0221",
    "percentualeG": null,
    "minQ-gr": "60.0000",
    "maxQ-gr": "240.0000",
    "round-g": "60",
    "unit": "60",
    "group": "uova",
    "label": "Proteolipidico",
    "colazione": "VERO",
    "spuntini": "VERO",
    "pranzo": "VERO",
    "cena": "VERO",
    "custom": "",
    "si-no": "VERO",
    "immagine": "/assets/img/food-icon/uova.svg"
  },
  {
    "idalimento": 27,
    "nome": "Albume",
    "c": "0.0010",
    "p": "0.1000",
    "f": "0.0050",
    "g": null,
    "percentualeC": "0.0001",
    "percentualeP": "0.0106",
    "percentualeF": "0.0005",
    "percentualeG": null,
    "minQ-gr": "40.0000",
    "maxQ-gr": "200.0000",
    "round-g": "50",
    "unit": "1",
    "group": "uova",
    "label": "Proteico",
    "colazione": "VERO",
    "spuntini": "VERO",
    "pranzo": "VERO",
    "cena": "VERO",
    "custom": "",
    "si-no": "VERO",
    "immagine": "/assets/img/food-icon/albume.svg"
  },
  {
    "idalimento": 28,
    "nome": "Latticini magri",
    "c": "0.0010",
    "p": "0.1200",
    "f": "0.0300",
    "g": null,
    "percentualeC": "0.0002",
    "percentualeP": "0.0181",
    "percentualeF": "0.0045",
    "percentualeG": null,
    "minQ-gr": "50.0000",
    "maxQ-gr": "250.0000",
    "round-g": "50",
    "unit": "1",
    "group": "latticini",
    "label": "Proteico",
    "colazione": "VERO",
    "spuntini": "VERO",
    "pranzo": "VERO",
    "cena": "VERO",
    "custom": "",
    "si-no": "VERO",
    "immagine": "/assets/img/food-icon/latticini.svg"
  },
  {
    "idalimento": 29,
    "nome": "Latticini freschi s.g.",
    "c": "0.0010",
    "p": "0.2000",
    "f": "0.2000",
    "g": null,
    "percentualeC": "0.0004",
    "percentualeP": "0.0802",
    "percentualeF": "0.0802",
    "percentualeG": null,
    "minQ-gr": "50.0000",
    "maxQ-gr": "250.0000",
    "round-g": "50",
    "unit": "1",
    "group": "latticini",
    "label": "Proteolipidico",
    "colazione": "VERO",
    "spuntini": "VERO",
    "pranzo": "VERO",
    "cena": "VERO",
    "custom": "",
    "si-no": "VERO",
    "immagine": "/assets/img/food-icon/latticini-freschi.svg"
  },
  {
    "idalimento": 30,
    "nome": "Formaggio semistagionato",
    "c": "0.0010",
    "p": "0.2500",
    "f": "0.2500",
    "g": null,
    "percentualeC": "0.0005",
    "percentualeP": "0.1253",
    "percentualeF": "0.1253",
    "percentualeG": null,
    "minQ-gr": "10.0000",
    "maxQ-gr": "60.0000",
    "round-g": "10",
    "unit": "1",
    "group": "latticini",
    "label": "Proteolipidico",
    "colazione": "VERO",
    "spuntini": "VERO",
    "pranzo": "VERO",
    "cena": "VERO",
    "custom": "",
    "si-no": "VERO",
    "immagine": "/assets/img/food-icon/formaggio-semistagionato.svg"
  },
  {
    "idalimento": 31,
    "nome": "Yogurt greco magro",
    "c": "0.0010",
    "p": "0.1000",
    "f": "0.0010",
    "g": null,
    "percentualeC": "0.0001",
    "percentualeP": "0.0102",
    "percentualeF": "0.0001",
    "percentualeG": null,
    "minQ-gr": "50.0000",
    "maxQ-gr": "250.0000",
    "round-g": "10",
    "unit": "1",
    "group": "yogurt",
    "label": "Proteico",
    "colazione": "VERO",
    "spuntini": "VERO",
    "pranzo": "VERO",
    "cena": "VERO",
    "custom": "",
    "si-no": "VERO",
    "immagine": "/assets/img/food-icon/yogurt-magro.svg"
  },
  {
    "idalimento": 32,
    "nome": "Fiocchi di latte",
    "c": "0.0010",
    "p": "0.1000",
    "f": "0.0400",
    "g": null,
    "percentualeC": "0.0001",
    "percentualeP": "0.0141",
    "percentualeF": "0.0056",
    "percentualeG": null,
    "minQ-gr": "50.0000",
    "maxQ-gr": "200.0000",
    "round-g": "10",
    "unit": "1",
    "group": "latticini",
    "label": "Proteico",
    "colazione": "VERO",
    "spuntini": "VERO",
    "pranzo": "VERO",
    "cena": "VERO",
    "custom": "",
    "si-no": "VERO",
    "immagine": "/assets/img/food-icon/fiocchi-latte.svg"
  },
  {
    "idalimento": 33,
    "nome": "Ricotta magra",
    "c": "0.0010",
    "p": "0.0900",
    "f": "0.0400",
    "g": null,
    "percentualeC": "0.0001",
    "percentualeP": "0.0118",
    "percentualeF": "0.0052",
    "percentualeG": null,
    "minQ-gr": "50.0000",
    "maxQ-gr": "200.0000",
    "round-g": "10",
    "unit": "1",
    "group": "latticini",
    "label": "Proteolipidico",
    "colazione": "VERO",
    "spuntini": "VERO",
    "pranzo": "VERO",
    "cena": "VERO",
    "custom": "",
    "si-no": "VERO",
    "immagine": "/assets/img/food-icon/ricotta.svg"
  },
  {
    "idalimento": 34,
    "nome": "Yogurt greco intero",
    "c": "0.0010",
    "p": "0.1000",
    "f": "0.0500",
    "g": null,
    "percentualeC": "0.0002",
    "percentualeP": "0.0151",
    "percentualeF": "0.0076",
    "percentualeG": null,
    "minQ-gr": "50.0000",
    "maxQ-gr": "250.0000",
    "round-g": "10",
    "unit": "1",
    "group": "yogurt",
    "label": "Proteolipidico",
    "colazione": "VERO",
    "spuntini": "VERO",
    "pranzo": "VERO",
    "cena": "VERO",
    "custom": "",
    "si-no": "VERO",
    "immagine": "/assets/img/food-icon/yogurt-greco.svg"
  },
  {
    "idalimento": 35,
    "nome": "Whey",
    "c": "0.0010",
    "p": "0.8000",
    "f": "0.0030",
    "g": null,
    "percentualeC": "0.0008",
    "percentualeP": "0.6432",
    "percentualeF": "0.0024",
    "percentualeG": null,
    "minQ-gr": "5.0000",
    "maxQ-gr": "30.0000",
    "round-g": "10",
    "unit": "1",
    "group": "cereali",
    "label": "Proteico",
    "colazione": "VERO",
    "spuntini": "VERO",
    "pranzo": "VERO",
    "cena": "VERO",
    "custom": "",
    "si-no": "VERO",
    "immagine": "/assets/img/food-icon/whey.svg"
  },
  {
    "idalimento": 36,
    "nome": "Barretta proteica >40%",
    "c": "0.0010",
    "p": "0.4000",
    "f": "0.0500",
    "g": null,
    "percentualeC": "0.0005",
    "percentualeP": "0.1804",
    "percentualeF": "0.0226",
    "percentualeG": null,
    "minQ-gr": "30.0000",
    "maxQ-gr": "70.0000",
    "round-g": "50",
    "unit": "50",
    "group": "latticini",
    "label": "Proteico",
    "colazione": "VERO",
    "spuntini": "VERO",
    "pranzo": "VERO",
    "cena": "VERO",
    "custom": "",
    "si-no": "VERO",
    "immagine": "/assets/img/food-icon/barretta.svg"
  },
  {
    "idalimento": 37,
    "nome": "Tofu",
    "c": "0.0010",
    "p": "0.1500",
    "f": "0.0800",
    "g": null,
    "percentualeC": "0.0002",
    "percentualeP": "0.0347",
    "percentualeF": "0.0185",
    "percentualeG": null,
    "minQ-gr": "40.0000",
    "maxQ-gr": "400.0000",
    "round-g": "50",
    "unit": "1",
    "group": "vegan",
    "label": "Proteolipidico",
    "colazione": "VERO",
    "spuntini": "VERO",
    "pranzo": "VERO",
    "cena": "VERO",
    "custom": "",
    "si-no": "VERO",
    "immagine": "/assets/img/food-icon/tofu.svg"
  },
  {
    "idalimento": 38,
    "nome": "Hamburger veg",
    "c": "0.0010",
    "p": "0.2000",
    "f": "0.0900",
    "g": null,
    "percentualeC": "0.0003",
    "percentualeP": "0.0582",
    "percentualeF": "0.0262",
    "percentualeG": null,
    "minQ-gr": "50.0000",
    "maxQ-gr": "200.0000",
    "round-g": "10",
    "unit": "1",
    "group": "vegan",
    "label": "Proteolipidico",
    "colazione": "VERO",
    "spuntini": "VERO",
    "pranzo": "VERO",
    "cena": "VERO",
    "custom": "",
    "si-no": "VERO",
    "immagine": "/assets/img/food-icon/hamb-vegetale.svg"
  },
  {
    "idalimento": 39,
    "nome": "Seitan",
    "c": "0.0010",
    "p": "0.2500",
    "f": "0.0300",
    "g": null,
    "percentualeC": "0.0003",
    "percentualeP": "0.0703",
    "percentualeF": "0.0084",
    "percentualeG": null,
    "minQ-gr": "50.0000",
    "maxQ-gr": "400.0000",
    "round-g": "10",
    "unit": "1",
    "group": "vegan",
    "label": "Proteico",
    "colazione": "VERO",
    "spuntini": "VERO",
    "pranzo": "VERO",
    "cena": "VERO",
    "custom": "",
    "si-no": "VERO",
    "immagine": "/assets/img/food-icon/seitan.svg"
  },
  {
    "idalimento": 40,
    "nome": "Quorn",
    "c": "0.0010",
    "p": "0.1300",
    "f": "0.0150",
    "g": null,
    "percentualeC": "0.0001",
    "percentualeP": "0.0190",
    "percentualeF": "0.0022",
    "percentualeG": null,
    "minQ-gr": "50.0000",
    "maxQ-gr": "400.0000",
    "round-g": "10",
    "unit": "1",
    "group": "vegan",
    "label": "Proteico",
    "colazione": "VERO",
    "spuntini": "VERO",
    "pranzo": "VERO",
    "cena": "VERO",
    "custom": "",
    "si-no": "VERO",
    "immagine": "/assets/img/food-icon/quorn.svg"
  },
  {
    "idalimento": 41,
    "nome": "Olio di oliva",
    "c": "0.0010",
    "p": "0.0010",
    "f": "0.9900",
    "g": null,
    "percentualeC": "0.0010",
    "percentualeP": "0.0010",
    "percentualeF": "0.9821",
    "percentualeG": null,
    "minQ-gr": "10.0000",
    "maxQ-gr": "30.0000",
    "round-g": "5",
    "unit": "1",
    "group": "condimenti",
    "label": "Lipidico",
    "colazione": "VERO",
    "spuntini": "VERO",
    "pranzo": "VERO",
    "cena": "VERO",
    "custom": "",
    "si-no": "VERO",
    "immagine": "/assets/img/food-icon/olio-oliva.svg"
  },
  {
    "idalimento": 42,
    "nome": "Parmigiano",
    "c": "0.0010",
    "p": "0.2500",
    "f": "0.3000",
    "g": null,
    "percentualeC": "0.0006",
    "percentualeP": "0.1378",
    "percentualeF": "0.1653",
    "percentualeG": null,
    "minQ-gr": "20.0000",
    "maxQ-gr": "50.0000",
    "round-g": "10",
    "unit": "1",
    "group": "latticini",
    "label": "Proteolipidico",
    "colazione": "VERO",
    "spuntini": "VERO",
    "pranzo": "VERO",
    "cena": "VERO",
    "custom": "",
    "si-no": "VERO",
    "immagine": "/assets/img/food-icon/parmigiano.svg"
  },
  {
    "idalimento": 43,
    "nome": "Formaggio duro",
    "c": "0.0010",
    "p": "0.2500",
    "f": "0.3000",
    "g": null,
    "percentualeC": "0.0006",
    "percentualeP": "0.1378",
    "percentualeF": "0.1653",
    "percentualeG": null,
    "minQ-gr": "20.0000",
    "maxQ-gr": "50.0000",
    "round-g": "10",
    "unit": "1",
    "group": "latticini",
    "label": "Proteolipidico",
    "colazione": "VERO",
    "spuntini": "VERO",
    "pranzo": "VERO",
    "cena": "VERO",
    "custom": "",
    "si-no": "VERO",
    "immagine": "/assets/img/food-icon/formaggio-duro.svg"
  },
  {
    "idalimento": 44,
    "nome": "Cocco",
    "c": "0.0500",
    "p": "0.0200",
    "f": "0.3300",
    "g": null,
    "percentualeC": "0.0200",
    "percentualeP": "0.0080",
    "percentualeF": "0.1320",
    "percentualeG": null,
    "minQ-gr": "10.0000",
    "maxQ-gr": "40.0000",
    "round-g": "10",
    "unit": "1",
    "group": "frutta",
    "label": "Lipidico",
    "colazione": "VERO",
    "spuntini": "VERO",
    "pranzo": "VERO",
    "cena": "VERO",
    "custom": "",
    "si-no": "VERO",
    "immagine": "/assets/img/food-icon/cocco.svg"
  },
  {
    "idalimento": 45,
    "nome": "Cioccolato 85",
    "c": "0.1500",
    "p": "0.0010",
    "f": "0.4500",
    "g": null,
    "percentualeC": "0.0902",
    "percentualeP": "0.0006",
    "percentualeF": "0.2705",
    "percentualeG": null,
    "minQ-gr": "10.0000",
    "maxQ-gr": "40.0000",
    "round-g": "10",
    "unit": "1",
    "group": "cioccolata",
    "label": "Lipidico",
    "colazione": "VERO",
    "spuntini": "VERO",
    "pranzo": "VERO",
    "cena": "VERO",
    "custom": "",
    "si-no": "VERO",
    "immagine": "/assets/img/food-icon/cioccolato85.svg"
  },
  {
    "idalimento": 46,
    "nome": "Cioccolato 90",
    "c": "0.1000",
    "p": "0.0010",
    "f": "0.5200",
    "g": null,
    "percentualeC": "0.0621",
    "percentualeP": "0.0006",
    "percentualeF": "0.3229",
    "percentualeG": null,
    "minQ-gr": "10.0000",
    "maxQ-gr": "40.0000",
    "round-g": "10",
    "unit": "1",
    "group": "cioccolata",
    "label": "Lipidico",
    "colazione": "VERO",
    "spuntini": "VERO",
    "pranzo": "VERO",
    "cena": "VERO",
    "custom": "",
    "si-no": "VERO",
    "immagine": "/assets/img/food-icon/cioccolato90.svg"
  },
  {
    "idalimento": 47,
    "nome": "Noci",
    "c": "0.0400",
    "p": "0.0700",
    "f": "0.6000",
    "g": null,
    "percentualeC": "0.0284",
    "percentualeP": "0.0497",
    "percentualeF": "0.4260",
    "percentualeG": null,
    "minQ-gr": "10.0000",
    "maxQ-gr": "40.0000",
    "round-g": "10",
    "unit": "1",
    "group": "frutta-secca",
    "label": "Lipidico",
    "colazione": "VERO",
    "spuntini": "VERO",
    "pranzo": "VERO",
    "cena": "VERO",
    "custom": "",
    "si-no": "VERO",
    "immagine": "/assets/img/food-icon/noci.svg"
  },
  {
    "idalimento": 48,
    "nome": "Mandorle",
    "c": "0.0800",
    "p": "0.0800",
    "f": "0.4500",
    "g": null,
    "percentualeC": "0.0488",
    "percentualeP": "0.0488",
    "percentualeF": "0.2745",
    "percentualeG": null,
    "minQ-gr": "10.0000",
    "maxQ-gr": "40.0000",
    "round-g": "10",
    "unit": "1",
    "group": "frutta-secca",
    "label": "Lipidico",
    "colazione": "VERO",
    "spuntini": "VERO",
    "pranzo": "VERO",
    "cena": "VERO",
    "custom": "",
    "si-no": "VERO",
    "immagine": "/assets/img/food-icon/mandorle.svg"
  },
  {
    "idalimento": 49,
    "nome": "Frutta secca",
    "c": "0.0500",
    "p": "0.0500",
    "f": "0.5000",
    "g": null,
    "percentualeC": "0.0300",
    "percentualeP": "0.0300",
    "percentualeF": "0.3000",
    "percentualeG": null,
    "minQ-gr": "10.0000",
    "maxQ-gr": "40.0000",
    "round-g": "10",
    "unit": "1",
    "group": "frutta-secca",
    "label": "Lipidico",
    "colazione": "VERO",
    "spuntini": "VERO",
    "pranzo": "VERO",
    "cena": "VERO",
    "custom": "",
    "si-no": "VERO",
    "immagine": "/assets/img/food-icon/frutta-secca.svg"
  },
  {
    "idalimento": 50,
    "nome": "Olio di cocco",
    "c": "0.0010",
    "p": "0.0010",
    "f": "0.9900",
    "g": null,
    "percentualeC": "0.0010",
    "percentualeP": "0.0010",
    "percentualeF": "0.9821",
    "percentualeG": null,
    "minQ-gr": "10.0000",
    "maxQ-gr": "30.0000",
    "round-g": "10",
    "unit": "1",
    "group": "condimenti",
    "label": "Lipidico",
    "colazione": "VERO",
    "spuntini": "VERO",
    "pranzo": "VERO",
    "cena": "VERO",
    "custom": "",
    "si-no": "VERO",
    "immagine": "/assets/img/food-icon/olio-cocco.svg"
  },
  {
    "idalimento": 51,
    "nome": "Porzione di verdura",
    "c": "0.1500",
    "p": "0.0000",
    "f": "0.0000",
    "g": null,
    "percentualeC": "0.0225",
    "percentualeP": "0.0000",
    "percentualeF": "0.0000",
    "percentualeG": null,
    "minQ-gr": "100.0000",
    "maxQ-gr": "500.0000",
    "round-g": "10",
    "unit": "1",
    "group": "verdura",
    "label": "Glucidico",
    "colazione": "VERO",
    "spuntini": "VERO",
    "pranzo": "VERO",
    "cena": "VERO",
    "custom": "",
    "si-no": "VERO",
    "immagine": "/assets/img/food-icon/verdura.svg"
  },
  {
    "idalimento": 52,
    "nome": "Spezzatino di soia",
    "c": "0.1500",
    "p": "0.4950",
    "f": "0.0018",
    "g": null,
    "percentualeC": "0.0970",
    "percentualeP": "0.3202",
    "percentualeF": "0.0012",
    "percentualeG": null,
    "minQ-gr": "100.0000",
    "maxQ-gr": "300.0000",
    "round-g": "10",
    "unit": "1",
    "group": "vegan",
    "label": "Proteico",
    "colazione": "VERO",
    "spuntini": "VERO",
    "pranzo": "VERO",
    "cena": "VERO",
    "custom": "",
    "si-no": "VERO",
    "immagine": "/assets/img/food-icon/soia.svg"
  },
  {
    "idalimento": 53,
    "nome": "Yogurt di Soia",
    "c": "0.1590",
    "p": "0.0350",
    "f": "0.0180",
    "g": null,
    "percentualeC": "0.0337",
    "percentualeP": "0.0074",
    "percentualeF": "0.0038",
    "percentualeG": null,
    "minQ-gr": "150.0000",
    "maxQ-gr": "300.0000",
    "round-g": "50",
    "unit": "1",
    "group": "latticini",
    "label": "Glucidico",
    "colazione": "VERO",
    "spuntini": "VERO",
    "pranzo": "VERO",
    "cena": "VERO",
    "custom": "",
    "si-no": "VERO",
    "immagine": "/assets/img/food-icon/yogurt-soia.svg"
  },
  {
    "idalimento": 54,
    "nome": "Cucchiai di olio evo",
    "c": "0.0010",
    "p": "0.0010",
    "f": "0.9900",
    "g": null,
    "percentualeC": "0.0010",
    "percentualeP": "0.0010",
    "percentualeF": "0.9821",
    "percentualeG": null,
    "minQ-gr": "10.0000",
    "maxQ-gr": "50.0000",
    "round-g": "10",
    "unit": "10",
    "group": "condimenti",
    "label": "Lipidico",
    "colazione": "VERO",
    "spuntini": "VERO",
    "pranzo": "VERO",
    "cena": "VERO",
    "custom": "",
    "si-no": "VERO",
    "immagine": "/assets/img/food-icon/olio-oliva.svg"
  },
  {
    "idalimento": 55,
    "nome": "Anacardi",
    "c": "0.1200",
    "p": "0.0800",
    "f": "0.4500",
    "g": null,
    "percentualeC": "0.0780",
    "percentualeP": "0.0520",
    "percentualeF": "0.2925",
    "percentualeG": null,
    "minQ-gr": "10.0000",
    "maxQ-gr": "40.0000",
    "round-g": "10",
    "unit": "1",
    "group": "frutta-secca",
    "label": "Lipidico",
    "colazione": "VERO",
    "spuntini": "VERO",
    "pranzo": "VERO",
    "cena": "VERO",
    "custom": "",
    "si-no": "VERO",
    "immagine": "/assets/img/food-icon/anacardi.svg"
  },
  {
    "idalimento": 58,
    "nome": "Food",
    "c": "0.1200",
    "p": "0.1800",
    "f": "0.0200",
    "g": null,
    "percentualeC": "0.0384",
    "percentualeP": "0.0576",
    "percentualeF": "0.0064",
    "percentualeG": null,
    "minQ-gr": "50.0000",
    "maxQ-gr": "200.0000",
    "round-g": "",
    "unit": "",
    "group": "prodotti forno",
    "label": "Glucoproteico",
    "colazione": "VERO",
    "spuntini": "VERO",
    "pranzo": "VERO",
    "cena": "VERO",
    "custom": "X",
    "si-no": "VERO",
    "immagine": null
  }
]

MEAL_COMPOSITOR_STATE = {
  currentState: 'ready',
  listOfIngredients: [],
  listOfRecipes: [],
  eventListenersTracking: {
    composeRecipe: {},
    modifyRecipe: {}
  }
}

/**
 * Set the meal compositor by adding the list of ingredients to the global variable,
 * appending the ingredients to the ingredients scroll, setting macro nutrient calculations to 0,
 * and adding event listeners for saving recipes, dropping ingredients, and deleting ingredients.
 *
 * @param {array} listOfIngredients - The list of ingredients to be added to the meal
 * @param {number} maxMealValues - An object containing the max values {calorie: number, c: number, p: number, g: number}
 */
function setMealCompositor(listOfIngredients, maxMealValues) {


  //////////////////////////////////////////////////////////////////
  // Get all the elements of the meal compositor
  //////////////////////////////////////////////////////////////////
  const mealCompositor = document.querySelector('.meal-compositor');
  const categorySelectors = mealCompositor.querySelector('.navigation-bar.top');
  const categoryButtons = categorySelectors.children;
  const progressBarsGroup = mealCompositor.querySelector('.progress-bar-group');
  const ingredientSelector = mealCompositor.querySelector('.food-horizontal-scroll');
  const ingredientDropArea = mealCompositor.querySelector('.dropped-food');
  const savedRecipesList = mealCompositor.querySelector('.list-of-recipes');

  const finishButton = mealCompositor.querySelector('#finish-meal-composition');
  const composeRecipeButton = mealCompositor.querySelector('.plate-droparea .action button');

  const composeRecipeModal = mealCompositor.querySelector('.modal.dialog-composizione-piatto');
  const editRecipeModal = mealCompositor.querySelector('.modal.dialog-modifica-piatto-preferiti');

  const ingredientCardExample = mealCompositor.querySelector('.food-horizontal-scroll .example').cloneNode(true);

  MEAL_COMPOSITOR_STATE.maxMealValues = maxMealValues;
  MEAL_COMPOSITOR_STATE.progressBarsGroup = progressBarsGroup;

  MEAL_COMPOSITOR_STATE.eventListenersTracking.composeRecipe.updateMealPlanCalculations = 0;
  MEAL_COMPOSITOR_STATE.eventListenersTracking.modifyRecipe.updateMealPlanCalculations = 0;


  //////////////////////////////////////////////////////////////////
  // Set the basic event listeners
  //////////////////////////////////////////////////////////////////

  // We show ingredients based on category, so we don't show em all
  for (const button of categoryButtons) {
    button.addEventListener('click', function () {
      showIngredientsbyCategory(this, ingredientSelector);
    });
  }

  // You can drag ingredients from the ingredien selector to the recipe compositor area, but also drag em back to the ingredien selector to delete them from the recipe compositor.
  ingredientSelector.ondragover = function (event) { event.preventDefault(); }
  ingredientSelector.ondrop = function (event) {
    event.preventDefault();
    removeIngredientFromMealCompositor(event, ingredientDropArea, composeRecipeButton);
  }

  // You need to drop the ingredients here to compose the recipe. It has a button for doing so that shows when we have more than 0 ingredients in the area
  ingredientDropArea.ondragover = function (event) { event.preventDefault(); }
  ingredientDropArea.ondrop = function (event) {
    event.preventDefault();
    addIngredientToMealCompositor(event, ingredientSelector, ingredientDropArea, composeRecipeButton);
  }

  // Button that shows the compose recipe modal
  // Recipe event listeners are set when saving the recipe
  composeRecipeButton.addEventListener('click', function () {
    composeRecipe(ingredientDropArea, savedRecipesList, composeRecipeModal, editRecipeModal);
  })

  // Make the button #finish-meal-composition change the global var state from 'ready' to 'finished'
  finishButton.addEventListener('click', function () {
    MEAL_COMPOSITOR_STATE.currentState = 'finished';
    console.log(`MEAL_COMPOSITOR_STATE.state: ${MEAL_COMPOSITOR_STATE.state}: No more functionality added for the 'Salva e Chiudi' button`);
  })

  //////////////////////////////////////////////////////////////////
  // Fill the ingredient selector with ingredients
  //////////////////////////////////////////////////////////////////

  // Pushing the ingredients JSON to the global var for easier access in other fucntions
  MEAL_COMPOSITOR_STATE.listOfIngredients = listOfIngredients;

  // Building the ingredient cards and appending the result to the ingredient selector
  for (const ingredient of listOfIngredients) {
    const ingredientCard = buildIngredientCard(ingredient, ingredientCardExample);
    ingredientSelector.appendChild(ingredientCard);
  }

  // Set macro nutrient calculations to 0 at the start
  setMaxValuesToMealPlanner(progressBarsGroup, maxMealValues);

  // Make the Carbohydrates menu selected at the start.
  categoryButtons[0].click();
}


/**
 * Show ingredients by category. (new 23/01/2023)
 *
 * @param {object} this - The context of the function.
 * @param {object} ingredientSelector - The selector for the ingredient elements.
 * @return {undefined} This function does not return a value.
 */
function showIngredientsbyCategory(clickedButton, ingredientSelector) {
  
  // Get the DOM of the category buttons
  const categorySelectors = clickedButton.parentElement;

  // Get the category of the button clicked by the user
  const clickedCategory = clickedButton.getAttribute('data-category');

  // Transform the category clicked by the user in something that can be matched against the JSON
  let ingredientsToShow = []
  switch (clickedCategory) {
    case 'carbohydrates':
      ingredientsToShow = ['Glucidico'];
      break;
    case 'proteins':
      ingredientsToShow = ['Proteico', 'Glucoproteico'];
      break;
    case 'fats':
      ingredientsToShow = ['Proteolipidico', 'Lipidico'];
      break;
    case 'fiber':
      ingredientsToShow = [];
      break;
  }

  // Hide all the elements in the ingredient selector, and show only the ones that match the category clicked by the user
  for (const child of ingredientSelector.children) {
    child.style.display = 'none';
    if (ingredientsToShow.length > 0) {
      if (ingredientsToShow.includes(child.getAttribute('data-category'))) {
        child.style.display = 'flex';
      }
    }
    else {
      let _f = child.getAttribute('data-fiber');
      if (_f) {
        let _fiber = parseFloat(_f).toFixed(4);
        if (_fiber > 0.01) {
          child.style.display = 'flex';
        }
      }
     
    }
  }

  // Remove .active from all category buttons and add it to the clicked one
  for (const button of categorySelectors.children) {
    button.classList.remove('active');
  }
  clickedButton.classList.add('active');
}


/**
 * Removes an ingredient from the meal compositor. (new 23/01/2023)
 *
 * @param {Event} event - The event that triggered the function.
 * @param {HTMLElement} ingredientDropArea - The drop area for ingredients.
 * @param {HTMLElement} composeRecipeButton - The button to compose a recipe.
 */
function removeIngredientFromMealCompositor(event, ingredientDropArea, composeRecipeButton) {
  const cardId = JSON.parse(event.dataTransfer.getData('text')).cardId;
  // Locate the element with such cardId in the ingredientDropArea and remove it
  const elementToRemove = ingredientDropArea.querySelector(`[data-cardId="${cardId}"]`);
  if (elementToRemove) {
    elementToRemove.remove();
  }

  // if ingredientDropArea is empty, hide the compose recipe button
  if (ingredientDropArea.children.length === 0) {
    composeRecipeButton.style.display = 'none';
  }

}

/**
 * Adds an ingredient card to the meal compositor. (new 23/01/2023)
 *
 * @param {Event} event - The drag event.
 * @param {HTMLElement} ingredientSelector - The ingredient selection container.
 * @param {HTMLElement} ingredientDropArea - The area where the ingredient card will be dropped.
 * @param {HTMLElement} composeRecipeButton - The button to compose the recipe.
 */
function addIngredientToMealCompositor(event, ingredientSelector, ingredientDropArea, composeRecipeButton) {
  // Add to DOM the very elemento that passes the drag event. We need to add a timestamp for identifying it in deletion (there may multiple similar cards) and add the ondragstart again, as is not preserver when cloning the node
  const dataId = JSON.parse(event.dataTransfer.getData('text')).idalimento;
  const timestamp = Date.now();

  // We can't have duplicated ingredients in the drop area, so we need to check if it already exists
  const isIngredientAlreadyInDroparea = ingredientDropArea.querySelector(`[data-id="${dataId}"]`);
  if (isIngredientAlreadyInDroparea) {
    console.warn(`Ingredient ID ${dataId} already in drop area! Preventing duplicate.`);
    return;
  }


  const clonedIngredientCard = ingredientSelector.querySelector(`[data-id="${dataId}"]`).cloneNode(true);
  clonedIngredientCard.setAttribute('data-cardId', timestamp);
  clonedIngredientCard.ondragstart = function (event) {
    // Add the data of the ingredient to the drag event
    event.dataTransfer.setData('text/plain', JSON.stringify({
      cardId: this.getAttribute('data-cardId')
    }));
  };

  ingredientDropArea.appendChild(clonedIngredientCard);


  // If there are more than 0 child nodes of ingrendientDropArea, show the compose recipe button
  if (ingredientDropArea.children.length > 0) {
    composeRecipeButton.style.display = 'block';
  } else {
    composeRecipeButton.style.display = 'none';
  }
}

/**
 * Builds an ingredient card based on the provided ingredient object. (updated 23/01/2023)
 *
 * @param {Object} ingredient - The ingredient object containing the necessary information.
 * @param {HTMLElement} ingredientCardExample - The example ingredient card to clone
 * @return {Element} - The newly created ingredient card.
 */
function buildIngredientCard(ingredient, ingredientCardExample) {
  
  // We make a copy of the ingredients car, so we don't modify the example
  const ingredientCardCopy = ingredientCardExample.cloneNode(true);

  // Remove the class .example from ingredientCardCopy
  ingredientCardCopy.classList.remove('example');

  // We make the ingredient draggable, so we can move it
  ingredientCardCopy.draggable = true;

  ingredientCardCopy.setAttribute('data-id', ingredient.idalimento);
  ingredientCardCopy.setAttribute('data-category', ingredient.label); // We'll use this one for displaying or hiding elements from the scroll based on category
  ingredientCardCopy.setAttribute('data-fiber', ingredient.f); 
  ingredientCardCopy.querySelector('.picture img').src = '/assets/img/food-icon/' + `${ingredient.immagine}`;
  ingredientCardCopy.querySelector('.title').innerText = ingredient.nome;

  // We set an ondragstart event on the ingredient card
  ingredientCardCopy.ondragstart = function (event) {
    // Add the data of the ingredient to the drag event
    event.dataTransfer.setData('text/plain', JSON.stringify({
      idalimento: this.getAttribute('data-id')
    }));
  };

  return ingredientCardCopy;
}

/**
 * Generates a recipe object based on the dropped ingredients in the meal compositor.
 *
 * @param {HTMLElement} ingredientDropArea - The drop area for ingredients.
 * @param {HTMLElement} savedRecipesList - The list of saved recipes.
 * @param {HTMLElement} composeRecipeModal - The modal .modal.dialog-composizione-piatto.
 * @param {HTMLElement} editRecipeModal - The modal .modal.dialog-modifica-piatto. *
 * @param {HTMLElement} progressBarsGroup - The group of progress bars.
 * @param {Object} maxMealValues - The maximum values for the total amount of meals.
 * @return {string} The name of the recipe.
 */
function composeRecipe(ingredientDropArea, savedRecipesList, composeRecipeModal, editRecipeModal) {

  // We use a temporal object to make things clear
  let tempRecipe = {
    localId: '',
    name: '',
    calories: '',
    ingredients: []
  };

  // Generate a hash for locally identifying the recipe in the frontend
  tempRecipe.localId = Date.now();

  // Create an ingredient object for each ingredient and store it in the tempRecipe
  for (const child of ingredientDropArea.children) {
    const thisIngredient = {
      idalimento: child.getAttribute('data-id'),
      amountOfIngredient: 0
    }
    tempRecipe.ingredients.push(thisIngredient);
  }

  // show the modal .modal.dialog-composizione-piatto changing the class hide to show
  composeRecipeModal.classList.remove('hide');
  composeRecipeModal.classList.add('show');

  // For every ingredient in the list, we need to ask for quantity with a .form-indicator
  // There's already one in the DOM that we'll use as model
  const formIndicatorModel = composeRecipeModal.querySelector('.form-indicator');
  formIndicatorModel.style.display = 'none';

  for (let i = 0; i < tempRecipe.ingredients.length; i++) {
    const newFormIndicator = formIndicatorModel.cloneNode(true);
    newFormIndicator.removeAttribute('style');
    newFormIndicator.setAttribute('data-id', tempRecipe.ingredients[i].idalimento);
    newFormIndicator.querySelector('.title').innerText = MEAL_COMPOSITOR_STATE.listOfIngredients.find(listedIngredient => listedIngredient.idalimento == tempRecipe.ingredients[i].idalimento).nome;

    const indicatorDefaultValue = MEAL_COMPOSITOR_STATE.listOfIngredients.find(listedIngredient => listedIngredient.idalimento == tempRecipe.ingredients[i].idalimento)['minQ-gr'];
    const indicatorMaxValue = MEAL_COMPOSITOR_STATE.listOfIngredients.find(listedIngredient => listedIngredient.idalimento == tempRecipe.ingredients[i].idalimento)['maxQ-gr'];

    newFormIndicator.setAttribute('data-minQ-gr', indicatorDefaultValue);
    newFormIndicator.setAttribute('data-maxQ-gr', indicatorMaxValue);

    newFormIndicator.querySelector('input').value = parseFloat(indicatorDefaultValue); // Set to minQ-gr at start
    newFormIndicator.querySelector('.number').innerText = parseFloat(indicatorDefaultValue); // Set to minQ-gr at start

    composeRecipeModal.querySelector('.indicators').appendChild(newFormIndicator);
  }


  const closeButton = composeRecipeModal.querySelector('.close img');
  const saveButton = composeRecipeModal.querySelector('.save');

  // If the user presses .close on the modal, hide it, and remove .indicators except the model one
  closeButton.onclick = function () {
    // Delete all childnodes modal .indicators that a data-id attribute, so we avoid deleting the .indicator model
    const indicators = composeRecipeModal.querySelectorAll('.indicators [data-id]');
    for (let index = 0; index < indicators.length; index++) {
      indicators[index].remove();
    }

    // Empty the value of #piatto-input
    composeRecipeModal.querySelector('#piatto-input').value = '';

    // Hide the modal
    composeRecipeModal.classList.remove('show');
    composeRecipeModal.classList.add('hide');
  }

  // If the user presses .save button we: Push the recipe to the global var and update the list of recipes
  saveButton.onclick = function () {

    // Get the name of the recipe (trimmed)
    tempRecipe.name = composeRecipeModal.querySelector('#piatto-input').value.trim();

    // Check if the name is empty or it only has spaces
    // If it does, don't save the recipe
    if (tempRecipe.name.trim() === '') {
      return;
    }

    //calories
    // Get the values of the form-indicators
    // Only procress indicators that have a data-id (we want to exclude the model one)
    const formIndicators = composeRecipeModal.querySelectorAll('.indicators [data-id]');


    let totalCalories = 0;
    let totalCarbohydrates = 0;
    let totalProteins = 0;
    let totalFats = 0;

    for (let i = 0; i < formIndicators.length; i++) {
      const thisIngredientId = formIndicators[i].getAttribute('data-id');
      const thisIngredientAmount = formIndicators[i].querySelector('input').value;
      tempRecipe.ingredients.find(ingredient => ingredient.idalimento == thisIngredientId).amountOfIngredient = thisIngredientAmount;

      const ingredientData = MEAL_COMPOSITOR_STATE.listOfIngredients.find(listedIngredient => listedIngredient.idalimento == thisIngredientId);


      if (ingredientData.c != null) { totalCarbohydrates += parseFloat(ingredientData.c) * thisIngredientAmount; }
      if (ingredientData.p != null) { totalProteins += parseFloat(ingredientData.p) * thisIngredientAmount; }
      if (ingredientData.f != null) { totalFats += parseFloat(ingredientData.f) * thisIngredientAmount; }
    }

    totalCalories = totalCarbohydrates * 4 + totalProteins * 4 + totalFats * 9;
    tempRecipe.calories = totalCalories;

    // Check if localId already exists. If it does, update the recipe. If it doesn't, push it to the global var.
    const localIdExists = MEAL_COMPOSITOR_STATE.listOfRecipes.some(recipe => recipe.localId == tempRecipe.localId);
    if (localIdExists) {
      // localId already exists: Delete the recipe object and push tempRecipe to it
      const index = MEAL_COMPOSITOR_STATE.listOfRecipes.findIndex(recipe => recipe.localId == tempRecipe.localId);
      MEAL_COMPOSITOR_STATE.listOfRecipes.splice(index, 1);
      MEAL_COMPOSITOR_STATE.listOfRecipes.push(tempRecipe);
    } else {
      // localId does not exist: push tempRecipe to the global var
      MEAL_COMPOSITOR_STATE.listOfRecipes.push(tempRecipe);
    }

    // Update the list of recipes in the DOM too
    updateSavedRecipes(savedRecipesList, editRecipeModal);

    // Update the progress bars in the meal compositor on saving the recipe
    updateMealPlanCalculations(MEAL_COMPOSITOR_STATE.progressBarsGroup, MEAL_COMPOSITOR_STATE.maxMealValues, false);

    // Close the modal
    closeButton.click();
  }

  // Every time the user modifies and indicator, call updateMealPlanCalculations() to update de progress bars inisde the compose modal
  // Also, prevent user from putting values below data-mingr and above data-maxgr
  const composeRecipeProgressBarGroup = composeRecipeModal.querySelector('.progress-bar-group');
  updateMealPlanCalculations(composeRecipeProgressBarGroup, MEAL_COMPOSITOR_STATE.maxMealValues, true); // Call it just when the user clicks compose the first time

  if (MEAL_COMPOSITOR_STATE.eventListenersTracking.composeRecipe.updateMealPlanCalculations < 1) {
    composeRecipeModal.querySelectorAll('.indicators [data-id] .plus').forEach(indicator => {
      indicator.addEventListener('click', function () {
        controlIngredientValues(this.parentElement.parentElement);
        updateMealPlanCalculations(composeRecipeProgressBarGroup, MEAL_COMPOSITOR_STATE.maxMealValues, true);
      });
    })

    composeRecipeModal.querySelectorAll('.indicators [data-id] .minus').forEach(indicator => {
      indicator.addEventListener('click', function () {
        controlIngredientValues(this.parentElement.parentElement);
        updateMealPlanCalculations(composeRecipeProgressBarGroup, MEAL_COMPOSITOR_STATE.maxMealValues, true);
      });
    })
  }

  // return the name of the recipe
  return tempRecipe.name;
}


/**
 * Modifies a recipe based on the given localId.
 *
 * @param {number} localId - The local ID of the recipe to modify.
 * @param {HTMLElement} savedRecipesList - The list of saved recipes.
 * @param {HTMLElement} editRecipeModal - The modal .modal.dialog-modifica-piatto.
 *
 */
function modifyRecipe(localId, savedRecipesList, editRecipeModal) {

  // get recipe data from the global var
  const thisRecipeData = MEAL_COMPOSITOR_STATE.listOfRecipes.find(recipe => recipe.localId == localId);

  // show the modal .modal.dialog-modifica-piatto-preferiti changing the class hide to show
  editRecipeModal.classList.remove('hide');
  editRecipeModal.classList.add('show');

  // Display the name of the recipe in the modal
  editRecipeModal.querySelector('#piatto-input').value = thisRecipeData.name;

  // For every ingredient in the list, we need to ask for quantity with a .form-indicator
  // There's already one in the DOM that we'll use as model
  const formIndicatorModel = editRecipeModal.querySelector('.form-indicator');
  formIndicatorModel.style.display = 'none';

  for (let i = 0; i < thisRecipeData.ingredients.length; i++) {
    const newFormIndicator = formIndicatorModel.cloneNode(true);
    newFormIndicator.removeAttribute('style');
    newFormIndicator.setAttribute('data-id', thisRecipeData.ingredients[i].idalimento);
    newFormIndicator.querySelector('.title').innerText = MEAL_COMPOSITOR_STATE.listOfIngredients.find(listedIngredient => listedIngredient.idalimento == thisRecipeData.ingredients[i].idalimento).nome;

    const indicatorDefaultValue = MEAL_COMPOSITOR_STATE.listOfIngredients.find(listedIngredient => listedIngredient.idalimento == thisRecipeData.ingredients[i].idalimento)['minQ-gr'];
    const indicatorMaxValue = MEAL_COMPOSITOR_STATE.listOfIngredients.find(listedIngredient => listedIngredient.idalimento == thisRecipeData.ingredients[i].idalimento)['maxQ-gr'];

    newFormIndicator.setAttribute('data-minQ-gr', indicatorDefaultValue);
    newFormIndicator.setAttribute('data-maxQ-gr', indicatorMaxValue);

    newFormIndicator.querySelector('input').value = thisRecipeData.ingredients[i].amountOfIngredient;
    newFormIndicator.querySelector('.number').innerText = thisRecipeData.ingredients[i].amountOfIngredient;
    editRecipeModal.querySelector('.indicators').appendChild(newFormIndicator);
  }

  const saveButton = editRecipeModal.querySelector('.save');
  const closeButton = editRecipeModal.querySelector('.close img');
  const deleteButton = editRecipeModal.querySelector('.delete');
  deleteButton.setAttribute('data-clickCount', 0);

  saveButton.onclick = function () {

    // Get the name of the recipe
    thisRecipeData.name = editRecipeModal.querySelector('#piatto-input').value;

    let totalCalories = 0;
    let totalCarbohydrates = 0;
    let totalProteins = 0;
    let totalFats = 0;

    // Get the values of the form-indicators
    const formIndicators = editRecipeModal.querySelector('.indicators').children;
    for (let i = 0; i < formIndicators.length; i++) {
      // Only procress indicators that have a data-id (we want to exclude the model one)
      if (formIndicators[i].hasAttribute('data-id')) {
        const thisIngredientId = formIndicators[i].getAttribute('data-id');
        const thisIngredientAmount = formIndicators[i].querySelector('input').value;
        thisRecipeData.ingredients.find(ingredient => ingredient.idalimento == thisIngredientId).amountOfIngredient = thisIngredientAmount;

        const ingredientData = MEAL_COMPOSITOR_STATE.listOfIngredients.find(listedIngredient => listedIngredient.idalimento == thisIngredientId);


        if (ingredientData.c != null) { totalCarbohydrates += parseFloat(ingredientData.c) * thisIngredientAmount; }
        if (ingredientData.p != null) { totalProteins += parseFloat(ingredientData.p) * thisIngredientAmount; }
        if (ingredientData.f != null) { totalFats += parseFloat(ingredientData.f) * thisIngredientAmount; }
      }
    }
    totalCalories = totalCarbohydrates * 4 + totalProteins * 4 + totalFats * 9;
    thisRecipeData.calories = totalCalories;

    // Update the recipe in the global var
    const index = MEAL_COMPOSITOR_STATE.listOfRecipes.findIndex(recipe => recipe.localId == thisRecipeData.localId);
    MEAL_COMPOSITOR_STATE.listOfRecipes.splice(index, 1);
    MEAL_COMPOSITOR_STATE.listOfRecipes.push(thisRecipeData);

    // Update the list of recipes in the DOM too
    updateSavedRecipes(savedRecipesList, editRecipeModal);

    // Update the progress bars in the meal compositor on saving the recipe
    updateMealPlanCalculations(MEAL_COMPOSITOR_STATE.progressBarsGroup, MEAL_COMPOSITOR_STATE.maxMealValues);
    closeButton.click();
  }

  deleteButton.onclick = function () {
    // Warn user abourt deleting (needs double click to delete)
    if (parseInt(this.getAttribute('data-clickCount')) == 0) {
      deleteButton.style.color = 'white';
      deleteButton.style.backgroundColor = 'red';
      deleteButton.innerText = 'Vuoi davvero eliminare la ricetta?';
    }

    // If he already clicked, set the button to its initial state and proceed with the deletion
    if (parseInt(this.getAttribute('data-clickCount')) == 1) {
      deleteButton.removeAttribute('style');
      deleteButton.innerText = 'Rimuovi';

      // Delete the recipe from the global var
      const index = MEAL_COMPOSITOR_STATE.listOfRecipes.findIndex(recipe => recipe.localId == thisRecipeData.localId);
      MEAL_COMPOSITOR_STATE.listOfRecipes.splice(index, 1);

      // click the close button
      closeButton.click();

      // Update the list of recipes
      updateSavedRecipes(savedRecipesList, editRecipeModal);

      // Update the progress bars in the meal compositor on saving the recipe
      updateMealPlanCalculations(MEAL_COMPOSITOR_STATE.progressBarsGroup, MEAL_COMPOSITOR_STATE.maxMealValues);
    }

    this.setAttribute('data-clickCount', 1);
  }

  // If the user presses .close on the modal, hide it and set it to its initial state
  closeButton.onclick = function () {
    editRecipeModal.classList.remove('show');
    editRecipeModal.classList.add('hide');

    // Delete all childnodes modal .indicators that a data-id attribute, so we avoid deleting the .indicator model
    const indicators = editRecipeModal.querySelectorAll('.indicators [data-id]');
    for (let index = 0; index < indicators.length; index++) {
      indicators[index].remove();
    }

    // Empty the value of #piatto-input
    editRecipeModal.querySelector('#piatto-input').value = '';

    // Set the button to its initial state
    deleteButton.removeAttribute('style');
    deleteButton.innerText = 'Rimuovi';
    deleteButton.setAttribute('data-clickCount', 0);
  }

  // Every time the user modifies and indicator, call updateMealPlanCalculations() to update de progress bars inisde the compose modal
  const modifyRecipeProgressBarGroup = editRecipeModal.querySelector('.progress-bar-group');
  updateMealPlanCalculations(modifyRecipeProgressBarGroup, MEAL_COMPOSITOR_STATE.maxMealValues, true); // Call it just when the user clicks compose the first time

  if (MEAL_COMPOSITOR_STATE.eventListenersTracking.modifyRecipe.updateMealPlanCalculations < 1) {
    editRecipeModal.querySelectorAll('.indicators [data-id] .plus').forEach(indicator => {
      indicator.addEventListener('click', function () {
        controlIngredientValues(this.parentElement.parentElement);
        updateMealPlanCalculations(modifyRecipeProgressBarGroup, MEAL_COMPOSITOR_STATE.maxMealValues, true);
      });
    })

    editRecipeModal.querySelectorAll('.indicators [data-id] .minus').forEach(indicator => {
      indicator.addEventListener('click', function () {
        controlIngredientValues(this.parentElement.parentElement);
        updateMealPlanCalculations(modifyRecipeProgressBarGroup, MEAL_COMPOSITOR_STATE.maxMealValues, true);
      });
    })
  }

}


/**
 * Updates the meal plan calculations based on the given maximum calories. (updated 23/01/2023)
 *
 * @param {Element} progressBarsGroup - The group of progress bars to update.
 * @param {Object} maxMealValues - The maximum values for each category.
 * @param {boolean} imInTheComposeModal - Whether the user is in the compose modal.
 */

function updateMealPlanCalculations(progressBarsGroup, maxMealValues, imInTheComposeModal = false) {

  // Get the max values for each category
  let maxCalories;
  let maxCarbohydrates;
  let maxProteins;
  let maxFats;
  try {
    maxCalories = maxMealValues.calorie;
    maxCarbohydrates = maxMealValues.c;
    maxProteins = maxMealValues.p;
    maxFats = maxMealValues.f;
  } catch (error) {
    console.error(error);
    maxCalories = 100;
    maxCarbohydrates = 100;
    maxProteins = 100;
    maxFats = 100;
  }

  // Identify the text for each category
  const caloriesString = progressBarsGroup.querySelector('.card-text-with-progress-bar[data-category="calories"] .info');
  const carbohydratesString = progressBarsGroup.querySelector('.meal-compositor .progress-bar-group .card-text-with-progress-bar[data-category="carbohydrates"] .info');
  const proteinsString = progressBarsGroup.querySelector('.meal-compositor .progress-bar-group .card-text-with-progress-bar[data-category="proteins"] .info');
  const fatsString = progressBarsGroup.querySelector('.meal-compositor .progress-bar-group .card-text-with-progress-bar[data-category="fats"] .info');

  // Identify the progress bars values for each category
  const caloriesProgressBar = progressBarsGroup.querySelector('.card-text-with-progress-bar[data-category="calories"] progress');
  const carbohydratesProgressBar = progressBarsGroup.querySelector('.card-text-with-progress-bar[data-category="carbohydrates"] progress');
  const proteinsProgressBar = progressBarsGroup.querySelector('.card-text-with-progress-bar[data-category="proteins"] progress');
  const fatsProgressBar = progressBarsGroup.querySelector('.card-text-with-progress-bar[data-category="fats"] progress');

  // Sum the total amount of calories, carbohydrates, proteins and fats present in saved plates
  // Formula for Kcals is kcal = (carbohydrates * 4) + (proteins * 4) + (fats * 9)
  let totalCalories = 0;
  let totalCarbohydrates = 0;
  let totalProteins = 0;
  let totalFats = 0;

  //try {
  //  for (const savedRecipe of MEAL_COMPOSITOR_STATE.listOfRecipes) {
  //    for (const ingredient of savedRecipe.ingredients) {
  //      const ingredientData = MEAL_COMPOSITOR_STATE.listOfIngredients.find(listedIngredient => listedIngredient.idalimento == ingredient.idalimento);

  //      if (ingredientData.c != null) { totalCarbohydrates += parseFloat(ingredientData.c) * ingredient.amountOfIngredient; }
  //      if (ingredientData.p != null) { totalProteins += parseFloat(ingredientData.p) * ingredient.amountOfIngredient; }
  //      if (ingredientData.f != null) { totalFats += parseFloat(ingredientData.f) * ingredient.amountOfIngredient; }
  //    }
  //  }
  //} catch (error) {
  //  console.error(`Couldn't iterate over recipes or ingredients:\n\n ${error}`);
  //}

  // Add to the total values the values of the ingredients being edited live in the meal compositor
  if (imInTheComposeModal == true) {
    // Get the ingredient ID and the amount of ingredient for each indicador and add that info to the total values
    try {
      const indicatorsList = progressBarsGroup.parentElement.querySelectorAll('.indicators [data-id]');
      for (const indicator of indicatorsList) {
        tempIngredient = {};
        tempIngredient.idalimento = indicator.getAttribute('data-id');
        tempIngredient.amountOfIngredient = indicator.querySelector('input').value;

        tempIngredient.c = MEAL_COMPOSITOR_STATE.listOfIngredients.find(listedIngredient => listedIngredient.idalimento == tempIngredient.idalimento).c;
        tempIngredient.p = MEAL_COMPOSITOR_STATE.listOfIngredients.find(listedIngredient => listedIngredient.idalimento == tempIngredient.idalimento).p;
        tempIngredient.f = MEAL_COMPOSITOR_STATE.listOfIngredients.find(listedIngredient => listedIngredient.idalimento == tempIngredient.idalimento).f;

        totalCarbohydrates += parseFloat(tempIngredient.c) * tempIngredient.amountOfIngredient;
        totalProteins += parseFloat(tempIngredient.p) * tempIngredient.amountOfIngredient;
        totalFats += parseFloat(tempIngredient.f) * tempIngredient.amountOfIngredient;
      }
    } catch (error) {
      console.error(error);
    }
  } else {

    try {
      for (const savedRecipe of MEAL_COMPOSITOR_STATE.listOfRecipes) {
        for (const ingredient of savedRecipe.ingredients) {
          const ingredientData = MEAL_COMPOSITOR_STATE.listOfIngredients.find(listedIngredient => listedIngredient.idalimento == ingredient.idalimento);

          if (ingredientData.c != null) { totalCarbohydrates += parseFloat(ingredientData.c) * ingredient.amountOfIngredient; }
          if (ingredientData.p != null) { totalProteins += parseFloat(ingredientData.p) * ingredient.amountOfIngredient; }
          if (ingredientData.f != null) { totalFats += parseFloat(ingredientData.f) * ingredient.amountOfIngredient; }
        }
      }
    } catch (error) {
      console.error(`Couldn't iterate over recipes or ingredients:\n\n ${error}`);
    }
  }

  totalCalories = totalCarbohydrates * 4 + totalProteins * 4 + totalFats * 9;

  if (imInTheComposeModal == false) {
    // Save the total values in MEAL_COMPOSITOR_STATE.totalValues
    MEAL_COMPOSITOR_STATE.totalValues = {
      totalCalories: totalCalories.toFixed(2),
      totalCarbohydrates: totalCarbohydrates.toFixed(2),
      totalProteins: totalProteins.toFixed(2),
      totalFats: totalFats.toFixed(2)
    };
  }

  // We update the progress strings and progress bars
  caloriesString.innerText = `${parseFloat(maxCalories).toFixed(2)} kcal`;
  carbohydratesString.innerText = `${parseFloat(maxCarbohydrates).toFixed(2)} g`;
  proteinsString.innerText = `${parseFloat(maxProteins).toFixed(2)} g`;
  fatsString.innerText = `${parseFloat(maxFats).toFixed(2)} g`;

  // We update the progress bars
  caloriesProgressBar.max = maxCalories;
  caloriesProgressBar.value = totalCalories;

  carbohydratesProgressBar.max = maxCarbohydrates;
  carbohydratesProgressBar.value = totalCarbohydrates;

  proteinsProgressBar.max = maxProteins;
  proteinsProgressBar.value = totalProteins;

  fatsProgressBar.max = maxFats;
  fatsProgressBar.value = totalFats;

  // If the current value of the progress bar is higher than the maximum value, we set the progress bar in red with setProgressBarToRed(thisElement)
  setProgressBarToRed(caloriesProgressBar, totalCalories, maxCalories);
  setProgressBarToRed(carbohydratesProgressBar, totalCarbohydrates, maxCarbohydrates);
  setProgressBarToRed(proteinsProgressBar, totalProteins, maxProteins);
  setProgressBarToRed(fatsProgressBar, totalFats, maxFats);

  function setProgressBarToRed(progressBar, currentValue, maxValue) {
    if (currentValue > maxValue) {
      if (!progressBar.classList.contains('flashtored')) {
        progressBar.classList.add('flashtored');
      }
    } else {
      try {
        progressBar.classList.remove('flashtored');
      } catch (error) {
        console.log(error);
      }
    }
  }

}

/**
 * Controls the value of an ingredient indicator based on a given indicator element.
 *
 * @param {HTMLElement} indicator - The indicator element.
 * @return {undefined} This function does not return a value.
 */
function controlIngredientValues(indicator) {
  const minValue = parseInt(indicator.getAttribute('data-minQ-gr'));
  const maxValue = parseInt(indicator.getAttribute('data-maxQ-gr'));
  let currentValue = parseInt(indicator.querySelector('input').value);

  if (currentValue < minValue) {
    indicator.querySelector('input').value = minValue;
    indicator.querySelector('.number').innerText = minValue;

    // Flashing the .number in red for 200ms
    flashInRed();
  }

  if (currentValue > maxValue) {
    indicator.querySelector('input').value = maxValue;
    indicator.querySelector('.number').innerText = maxValue;

    // Flashing the .number in red for 200ms
    flashInRed();
  }

  // Function defined inside of function to flash text in red
  function flashInRed() {
    indicator.querySelector('.number').setAttribute('style', 'color: var(--red-error); transition: 100ms;');
    setTimeout(() => {
      indicator.querySelector('.number').removeAttribute('style');
    }, 200);
  }
}

/**
 * Sets the maximum values for the meal planner.
 *
 * @param {Element} progressBarsGroup - The group of progress bars.
 * @param {Object} maxMealValues - The maximum values for each category.
 * @return {void} No return value.
 */
function setMaxValuesToMealPlanner(progressBarsGroup, maxMealValues) {
  // Get the max values for each category
  let maxCalories;
  let maxCarbohydrates;
  let maxProteins;
  let maxFats;
  try {
    maxCalories = maxMealValues.calorie;
    maxCarbohydrates = maxMealValues.c;
    maxProteins = maxMealValues.p;
    maxFats = maxMealValues.f;
  } catch (error) {
    console.error(error);
    maxCalories = 100;
    maxCarbohydrates = 100;
    maxProteins = 100;
    maxFats = 100;
  }

  // Identify the text for each category
  const caloriesString = progressBarsGroup.querySelector('.card-text-with-progress-bar[data-category="calories"] .info');
  const carbohydratesString = progressBarsGroup.querySelector('.meal-compositor .progress-bar-group .card-text-with-progress-bar[data-category="carbohydrates"] .info');
  const proteinsString = progressBarsGroup.querySelector('.meal-compositor .progress-bar-group .card-text-with-progress-bar[data-category="proteins"] .info');
  const fatsString = progressBarsGroup.querySelector('.meal-compositor .progress-bar-group .card-text-with-progress-bar[data-category="fats"] .info');

  // Identify the progress bars values for each category
  const caloriesProgressBar = progressBarsGroup.querySelector('.card-text-with-progress-bar[data-category="calories"] progress');
  const carbohydratesProgressBar = progressBarsGroup.querySelector('.card-text-with-progress-bar[data-category="carbohydrates"] progress');
  const proteinsProgressBar = progressBarsGroup.querySelector('.card-text-with-progress-bar[data-category="proteins"] progress');
  const fatsProgressBar = progressBarsGroup.querySelector('.card-text-with-progress-bar[data-category="fats"] progress');

  // Put the values in the strings
  if (maxMealValues.calorie != null) { caloriesString.innerText = `${maxCalories} kcal` }
  if (maxMealValues.c != null) { carbohydratesString.innerText = `${maxCarbohydrates} g` }
  if (maxMealValues.p != null) { proteinsString.innerText = `${maxProteins} g` }
  if (maxMealValues.f != null) { fatsString.innerText = `${maxFats} g` }

  // We update the progress bars
  caloriesProgressBar.max = maxCalories;
  caloriesProgressBar.value = 0;

  carbohydratesProgressBar.max = maxCarbohydrates;
  carbohydratesProgressBar.value = 0;

  proteinsProgressBar.max = maxProteins;
  proteinsProgressBar.value = 0;

  fatsProgressBar.max = maxFats;
  fatsProgressBar.value = 0;
}

/**
 * Updates the saved recipes list with the provided recipe list or the default recipe list from MEAL_COMPOSITOR_STATE.
 * @param {Array} savedRecipesList - The DOM list that we want to update with recipeList.
 * @param {Element} editRecipeModal - The modal that will show when the user presses the recipe card.
 * @param {Array} recipeList - The list of recipes that we want to put into the DOM. Defaults to MEAL_COMPOSITOR_STATE.listOfRecipes.
 */
function updateSavedRecipes(savedRecipesList, editRecipeModal, recipeList = MEAL_COMPOSITOR_STATE.listOfRecipes) {
  // Get the ingredients card example from DOM
  const ingredientCard = document.querySelector('.food-horizontal-scroll .example');

  // Delete the .saved-recipes .list-of-recipes children
  savedRecipesList.innerHTML = '';

  // We make a recipe card (taking the ingredient card as model) for every recipe in the list
  for (const recipe of recipeList) {
    // We make a copy of the ingredients card, so we don't modify the example
    const ingredientCardCopy = ingredientCard.cloneNode(true);

    // Remove the class .example and attribute display from ingredientCardCopy
    ingredientCardCopy.classList.remove('example');
    ingredientCardCopy.removeAttribute('style');

    // The recipe cards shouldn't be draggable
    ingredientCardCopy.draggable = false;

    // We set the attributes
    ingredientCardCopy.querySelector('.picture img').src = '/assets/img/food-icon/recipe.svg';
    ingredientCardCopy.querySelector('.title').innerText = recipe.name;
    ingredientCardCopy.setAttribute('data-localId', recipe.localId);

    // This recipes should also show the modify recipe modal when clicked
    ingredientCardCopy.addEventListener('click', function () {
      modifyRecipe(this.getAttribute('data-localId'), savedRecipesList, editRecipeModal);
    });

    // Append the ingredient card copy to the .saved-recipes .list-of-recipes
    savedRecipesList.appendChild(ingredientCardCopy);
  }

}



// ============================================================================
//
//                              Task Editor
//
// ============================================================================

function editElement(element) {
  // Save the original content
  const taskEditor = element.parentElement.parentElement;
  const imgElement = element.cloneNode(true);
  const originalTitle = taskEditor.querySelector('.title').innerText;
  const originalDescription = taskEditor.querySelector('.description').innerText;

  // Create a new input element for the title
  const titleInputElement = document.createElement('input');
  titleInputElement.value = originalTitle;

  // Create a new input element for the description
  const descriptionInputElement = document.createElement('input');
  descriptionInputElement.value = originalDescription;

  // Replace the original title with the new input element
  const titleElement = taskEditor.querySelector('.title');
  titleElement.innerHTML = '';
  titleElement.appendChild(titleInputElement);

  // Replace the original description with the new input element
  const descriptionElement = taskEditor.querySelector('.description');
  descriptionElement.innerHTML = '';
  descriptionElement.appendChild(descriptionInputElement);

  // Focus the new title input element
  titleInputElement.focus();

  // Listen for the keypress event on the title input element
  titleInputElement.addEventListener('keypress', function (event) {
    if (event.key === 'Enter') {
      // Save the changes to the title
      const newTitle = titleInputElement.value;
      titleElement.innerHTML = newTitle;
      titleElement.appendChild(imgElement);

      // Focus the new description input element
      descriptionInputElement.focus();
    }
  });

  // Listen for the keypress event on the description input element
  descriptionInputElement.addEventListener('keypress', function (event) {
    if (event.key === 'Enter') {
      // Save the changes to the description
      const newDescription = descriptionInputElement.value;
      descriptionElement.innerHTML = newDescription;
    }
  });
}
